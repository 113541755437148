import {INTERVAL_TOKEN_CHECK} from "@utils/constants"
import {isTokenValid} from "@utils/session"
import {FC, useEffect} from "react"
import {useNavigate} from "react-router"
import {Navigate, Outlet, useLocation} from "react-router-dom"
import useLogout from "@hooks/useLogout"
import useAuth from "@hooks/useAuth"
import {SessionUser} from "@typeuser"
import {OAuthApi} from "@api/oauth"

type TPropsRequireAuth = {}

// noinspection JSUnusedLocalSymbols
const RequireAuth: FC<TPropsRequireAuth> = () => {
    const location = useLocation()
    const logout = useLogout()
    const navigate = useNavigate()
    const {user, setUser} = useAuth()

    useEffect(() => {
        OAuthApi.currentUser()
            .then((user: SessionUser) => setUser(user))
            .catch(() => setUser(null))
    }, [setUser])

    // Every interval check if the token is still valid otherwise set auth to null
    useEffect(() => {
        const interval = setInterval(() => {
            if (!isTokenValid()) {
                logout().then(() => navigate("/login"))
            }
        }, INTERVAL_TOKEN_CHECK)
        return () => clearInterval(interval)
    }, [logout, navigate])

    return (
        isTokenValid()
            ? user ? <Outlet/> : undefined
            : <Navigate to="/login" state={{from: location}} replace/>
    )
}

export default RequireAuth