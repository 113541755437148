import React from "react"
import dayjs from "dayjs"
import {TableCell, TableRow, Typography} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import {SpeedTestApi} from "@api/speedtest"
import RestoreIcon from "@mui/icons-material/Restore"
import {MinimalAuthUser} from "../query/users.query"

type AuthUserLineProps = {
    user: MinimalAuthUser
    showDetail: (userId: number) => void
    onChange: () => void
}

export const AuthUserLine: React.FC<AuthUserLineProps> = (props) => {
    const {user, onChange, showDetail} = props

    const isActive = dayjs(user.expires).isAfter(dayjs())
    const latestTest = user.speedtestUsers.length
        ? user.speedtestUsers.reduce((a, b) => (a.timestamp > b.timestamp ? a : b))
        : null

    return (
        <TableRow hover={true}
                  style={{cursor: "pointer"}}
                  onClick={() => showDetail(user.id)}>
            <TableCell>
                <Typography>{user.name}</Typography>
            </TableCell>
            <TableCell>
                <Typography>{user.email}</Typography>
            </TableCell>
            <TableCell>
                <Typography color={isActive ? "success" : "error"}>
                    {user.expires}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography>
                    {latestTest ? latestTest.timestamp : `No tests available`}
                </Typography>
            </TableCell>
            <TableCell align="center">
                <Typography>
                    {isActive
                        ? (
                            <DeleteIcon
                                color="secondary"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    SpeedTestApi.expireUser(user.id)
                                        .then(() => onChange())
                                }}
                            />
                        )
                        : (
                            <RestoreIcon
                                color="inherit"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    SpeedTestApi.renewUser(user.id)
                                        .then(() => onChange())
                                }}
                            />
                        )}
                </Typography>
            </TableCell>
        </TableRow>)
}


