import {createTheme} from "@mui/material"
import {ACCENT, DARK, LIGHT, SECONDARY} from "./colors"
import {HEADER_HEIGHT} from "@utils/constants"
import {css, Global} from "@emotion/react"

const scrollbarStyles = css`
    /* Scrollbar styles for Webkit browsers (Chrome, Safari) */

    ::-webkit-scrollbar {
        width: 12px;
    }

    ::-webkit-scrollbar-track {
        background: ${DARK};
    }

    ::-webkit-scrollbar-thumb {
        background-color: #555;
        border-radius: 10px;
        border: 3px solid ${DARK};
    }

    /* Scrollbar styles for Firefox */

    * {
        scrollbar-width: thin;
        scrollbar-color: #555 ${DARK};
    }
`

export const darkTheme = createTheme({
    palette: {
        mode: "dark",
        background: {
            default: DARK,
            paper: DARK,
        },
        primary: {
            main: LIGHT,
        },
        secondary: {
            main: SECONDARY,
        },
        text: {
            primary: LIGHT,
            secondary: SECONDARY,
        },
    },
    typography: {
        fontFamily: "Roboto, sans-serif",
        fontSize: 11.2,
    },
    components: {
        MuiCardContent: {
            styleOverrides: {
                root: {
                    "&:last-child": {
                        paddingBottom: "0px",
                    },
                },
            },
        },
        MuiFab: {
            styleOverrides: {
                root: {
                    color: DARK,
                    backgroundColor: LIGHT,
                    fontSize: "1.5rem",
                    "&:hover": {
                        color: DARK,
                        backgroundColor: "#888",
                    },
                },
            },
        },
        MuiSpeedDial: {
            styleOverrides: {
                fab: {
                    color: DARK,
                    backgroundColor: LIGHT,
                    lineHeight: "normal",
                },
            },
        },
        MuiSpeedDialAction: {
            styleOverrides: {
                fab: {
                    ":hover": {
                        color: DARK,
                        backgroundColor: "#888",
                    },
                    color: DARK,
                    backgroundColor: LIGHT,
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    border: `1px solid grey`,
                    backgroundColor: DARK,
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    border: "2px solid #ccc",
                    backgroundImage: "none",
                },
            },
        },
        MuiAvatarGroup: {
            styleOverrides: {
                root: {
                    "& .MuiAvatar-root": {
                        border: `1px solid grey`,
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    // color: LIGHT,  // overrides the color of all icons
                },
            },

        },
        MuiSwitch: {
            styleOverrides: {
                switchBase: {
                    color: LIGHT,
                    "&.Mui-checked": {
                        color: LIGHT,
                    },
                    "&.Mui-checked + .MuiSwitch-track": {
                        opacity: 1,
                        backgroundColor: ACCENT,
                    },
                    "& + .MuiSwitch-track": {
                        backgroundColor: "#666",
                    },
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    color: LIGHT,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: LIGHT,
                },
            },

        },
        MuiButton: {
            styleOverrides: {
                root: {
                    color: DARK,
                    "&:hover": {
                        color: DARK,
                        backgroundColor: "#888",
                    },
                    "&.Mui-disabled": {
                        color: "gray",
                    },
                },
                text: {
                    color: LIGHT,
                },
                outlined: {
                    color: LIGHT,
                    borderColor: LIGHT,
                    "&:hover": {
                        color: DARK,
                        backgroundColor: "#888",
                    },
                },
                contained: {
                    backgroundColor: LIGHT,
                },
            },
        },
    },
    mixins: {
        toolbar: {
            minHeight: HEADER_HEIGHT,
            "@media (min-width:0px) and (orientation: landscape)": {
                minHeight: HEADER_HEIGHT,
            },
            "@media (min-width:600px)": {
                minHeight: HEADER_HEIGHT,
            },
        },
    },
})

const GlobalStyles = () => <Global styles={scrollbarStyles}/>

export {GlobalStyles}
export default darkTheme
