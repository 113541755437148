import {Box, CssBaseline, ThemeProvider} from "@mui/material"
import {lazy, Suspense} from "react"
import {Route, Routes} from "react-router-dom"
import PageLayout from "./PageLayout"
import PersistLogin from "./PersistLogin"
import RequireAuth from "./RequireAuth"
import theme from "../theme/index"
import {GlobalStyles} from "../theme/dark"
import {PageRoles} from "@utils/roles"
import RequireRole from "./RequireRole"
import buildProjectRoutes from "../features/ProjectPage/routes"
import {buildSpeedTestRoutes} from "../features/SpeedTestPage/routes"
import {DARK} from "../theme/colors"

const HomePage = lazy(() => import("../features/HomePage"))
const PeoplePage = lazy(() => import("../features/PeoplePage"))
const ProjectLeads = lazy(() => import("../features/ProjectLeads"))
const LoginScreen = lazy(() => import("../pages/LoginPage"))
const AuthorizeScreen = lazy(() => import("../pages/AuthorizeScreen"))
const NotFoundScreen = lazy(() => import("../pages/MissingPage"))
const FilesystemPage = lazy(() => import("../features/FilesystemPage"))
const ProjectsPage = lazy(() => import("../features/ProjectsPage"))
const WorkstationsPage = lazy(() => import("../features/WorkstationsPage"))
const PersonPage = lazy(() => import("../features/PersonPage"))
const RenderFarmPage = lazy(() => import("../features/RenderFarm"))

function App() {
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles/>
            <CssBaseline/>
            <Suspense fallback={<Box sx={{height: '100vh', backgroundColor: DARK}}/>}>
                <Routes>
                    <Route element={<PersistLogin/>}>
                        <Route path="login" element={<LoginScreen/>}/>
                        <Route path="authorize" element={<AuthorizeScreen/>}/>
                        <Route path="/" element={<PageLayout/>}>
                            <Route element={<RequireAuth/>}>
                                <Route path="/" element={<HomePage/>}/>
                                <Route path="/people" element={<PeoplePage/>}/>

                                <Route element={<RequireRole roles={PageRoles.Workstations}/>}>
                                    <Route path="/workstations" element={<WorkstationsPage/>}/>
                                </Route>
                                <Route element={<RequireRole roles={PageRoles.Projects}/>}>
                                    <Route path="/projects" element={<ProjectsPage/>}/>
                                </Route>
                                <Route element={<RequireRole roles={PageRoles.RenderFarm}/>}>
                                    <Route path="/render" element={<RenderFarmPage/>}/>
                                </Route>
                                <Route element={<RequireRole roles={PageRoles.Files}/>}>
                                    <Route path="/files" element={<FilesystemPage/>}/>
                                </Route>
                                <Route element={<RequireRole roles={PageRoles.ProjectLeads}/>}>
                                    <Route path="/leads" element={<ProjectLeads/>}/>
                                </Route>
                                <Route element={<RequireRole roles={PageRoles.SpeedTest}/>}>
                                    {buildSpeedTestRoutes()}
                                </Route>
                            </Route>
                        </Route>
                        {/* Disable layout for full page view */}
                        <Route element={<RequireAuth/>}>
                            <Route path="/person/:personId" element={<PersonPage/>}/>
                            {buildProjectRoutes()}
                        </Route>
                    </Route>
                    <Route path="*" element={<NotFoundScreen/>}/>
                </Routes>
            </Suspense>
        </ThemeProvider>
    )
}

export default App