import {gql} from "@apollo/client"

export type ProjectResults = {
    projects: {
        results: Project[]
    }
}

export type Project = {
    id: string
    name: string
    title: string
}

export const Query = gql`
    query ($projectId: [String!] = "") {
        projects(filters: {id: $projectId}) {
            results {
                id
                name
                title
            }
        }
    }
`
