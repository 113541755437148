import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import {FC, ReactNode} from "react"
import {HEADER_HEIGHT} from "@utils/constants"
import {useNavigate, useLocation} from "react-router"
import AppTooltip from "@components/AppTooltip"

type TProps = {
    route: string
    title: string
    icon: ReactNode
    open: boolean
}

const PageLink: FC<TProps> = (props) => {
    const {open, route, title, icon} = props
    const navigate = useNavigate()
    const location = useLocation()
    const selected = location.pathname.startsWith(route)

    const baseIcon = (
        <ListItemButton
            sx={{
                minHeight: HEADER_HEIGHT,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                backgroundColor: selected ? "rgba(255, 255, 255, 0.08)" : "transparent",
                '&:hover': {
                    backgroundColor: selected ? "rgba(255, 255, 255, 0.08)" : "rgba(0, 0, 0, 0.04)",
                },
            }}
            onClick={() => navigate(route)}
        >
            <ListItemIcon
                sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                }}
            >
                {icon}
            </ListItemIcon>
            <ListItemText
                primary={title}
                sx={{
                    opacity: open ? 1 : 0,
                }}
            />
        </ListItemButton>
    )

    const listIcon = open
        ? baseIcon
        : (
            <AppTooltip title={title} placement={"right"}>
                {baseIcon}
            </AppTooltip>
        )

    return (
        <ListItem key={route} disablePadding sx={{display: "block"}}>
            {listIcon}
        </ListItem>
    )
}

export default PageLink