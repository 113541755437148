import {useNavigate} from "react-router-dom"

const useNavigateWithParams = () => {
    const navigate = useNavigate()
    return (url: string, additionalParams?: Record<string, string>) => {
        const params = new URLSearchParams(window.location.search)

        if (additionalParams) {
            Object.keys(additionalParams).forEach(key => {
                params.set(key, additionalParams[key])
            })
        }

        navigate(`${url}?${params.toString()}`)
    }
}

export default useNavigateWithParams