import {gql} from "@apollo/client"

export type AuthUsersResults = {
    authUser: MinimalAuthUser[]
}

export type MinimalAuthUser = {
    id: number
    name: string
    email: string
    expires: string
    speedtestUsers: {
        timestamp: string
    }[]
}

export const UsersQuery = gql`
  query {
    authUser {
      id
      name
      email
      expires
      speedtestUsers {
        timestamp
      }
    }
  }
`
