import {runMethod} from "@api/base"

export type AsperaUser = {
    user_id: number
    username: string
    email: string
    first_name: string
    last_name: string
    created_at: string
    updated_at: string
    unique_email: string
    unique_username: string
    login_token_expires_at: string | null
    password_changed_on: string | null
    password_status: string | null
}

// export type AsperaGroup = {
//     group_id: number
//     name: string
//     created_at: string
//     updated_at: string
// }

export type AsperaShare = {
    id: number
    name: string
    directory: string
    created_at: string
    updated_at: string
    node_id: number
    browse_count: number
    bytes_free_string: string | null
    bytes_total_string: string | null
    bytes_unknown: boolean
    comment_added_at: string | null
    content_added_at: string | null
    error_bytes_free_size: string | null
    error_percent_free: string | null
    home_share: boolean
    percent_free: string | null
    status: string | null
    status_at: string | null
    status_message: string | null
    warn_bytes_free_size: string | null
    warn_percent_free: string | null
}

export type AsperaPermission = {
    share_id: number
    authorizable_id: number
    authorizable_type: string
    created_at: string
    updated_at: string
    content_availability_permission: boolean
    browse_permission: boolean
    delete_permission: boolean
    download_permission: boolean
    manage_permission: boolean
    mkdir_permission: boolean
    rename_permission: boolean
    upload_permission: boolean
}

export type AsperaNode = {
    id: number
    name: string
    created_at: string
    updated_at: string
    api_port: number
    api_prefix: string | null
    api_username: string
    azu_access_key: string | null
    azu_path: string | null
    azu_storage_account: string | null
    browse_count: number
    bytes_free_string: string | null
    bytes_total_string: string | null
    bytes_unknown: boolean
    cluster_uuid: string | null
    docroot: string | null
    error_bytes_free_size: string | null
    error_percent_free: string | null
    host: string | null
    http_fallback_port: string | null
    open_timeout: number
    percent_free: string | null
    ref_uuid: string
    salt: string
    sas_path: string | null
    sas_url: string | null
    ssh_port: string | null
    ssl: boolean
    status: string | null
    status_at: string | null
    status_message: string | null
    storage_type: string | null
    sync_enabled: boolean
    timeout: number
    verify_ssl: boolean
    warn_bytes_free_size: string | null
    warn_percent_free: string | null
}

export type UserShareResponse = {
    user: AsperaUser
    share: AsperaShare
}

const createLocalUser = async (
    username: string,
    email: string,
    firstName: string,
    lastName: string,
    sendWelcomeEmail: boolean = true,
): Promise<AsperaUser> => {
    const response = await runMethod<{ data: AsperaUser }>({
        method: "POST",
        route: "/api/v1/aspera/local_users",
        body: {
            username,
            email,
            first_name: firstName,
            last_name: lastName,
            send_welcome_email: sendWelcomeEmail,
        },
    })
    return response.data || []
}

const listLocalUsers = async (): Promise<AsperaUser[]> => {
    const response = await runMethod<{ data: AsperaUser[] }>({
        method: "GET",
        route: "/api/v1/aspera/local_users",
    })
    return response.data || []
}

// const removeLocalUser = async (userId: number): Promise<AsperaUser> => {
//     const response = await runMethod({
//         method: "DELETE",
//         route: `/api/v1/aspera/local_users/${userId}`,
//     })
//     return response.data || []
// }

const attachShares = async (
    projectId: string,
    shareIds: number[],
): Promise<void> => {
    await runMethod({
        method: "POST",
        route: "/api/v1/aspera/share/attach",
        body: {
            project_id: projectId,
            share_ids: shareIds,
        },
    })
}

const detachShare = async (
    projectId: string,
    shareId: number,
): Promise<void> => {
    await runMethod({
        method: "POST",
        route: "/api/v1/aspera/share/detach",
        body: {
            project_id: projectId,
            share_id: shareId,
        },
    })
}

const createShare = async (
    projectId: string,
    nodeId: number,
    name: string,
    directory: string,
    createDirectory: boolean,
): Promise<AsperaShare> => {
    const response = await runMethod<{ data: AsperaShare }>({
        method: "POST",
        route: "/api/v1/aspera/shares",
        body: {
            project_id: projectId,
            node_id: nodeId,
            name,
            directory,
            create_directory: createDirectory,
        },
    })
    return response.data || []
}

const listShares = async (
    projectId: string | null = null,
): Promise<AsperaShare[]> => {
    const url = projectId
        ? `/api/v1/aspera/shares?project_id=${projectId}`
        : "/api/v1/aspera/shares"
    const response = await runMethod<{ data: AsperaShare[] }>({
        method: "GET",
        route: url,
    })
    return response.data || []
}

const removeShare = async (shareId: number): Promise<AsperaShare> => {
    const response = await runMethod<{ data: AsperaShare }>({
        method: "DELETE",
        route: `/api/v1/aspera/shares/${shareId}`,
    })
    return response.data || []
}

const createPermission = async (
    shareId: number,
    userId: number,
): Promise<UserShareResponse> => {
    const response = await runMethod<{ data: UserShareResponse }>({
        method: "POST",
        route: "/api/v1/aspera/permissions",
        body: {
            share_id: shareId,
            user_id: userId,
        },
    })
    return response.data || []
}

const listPermissions = async (shareId: number): Promise<AsperaPermission[]> => {
    const response = await runMethod<{ data: AsperaPermission[] }>({
        method: "GET",
        route: `/api/v1/aspera/permissions/${shareId}`,
    })
    return response.data || []
}

const removePermission = async (
    shareId: number,
    userId: number,
): Promise<UserShareResponse> => {
    const response = await runMethod<{ data: UserShareResponse }>({
        method: "DELETE",
        route: `/api/v1/aspera/permissions/${shareId}/user/${userId}`,
    })
    return response.data || []
}

const listNodes = async (): Promise<AsperaNode[]> => {
    const response = await runMethod<{ data: AsperaNode[] }>({
        method: "GET",
        route: "/api/v1/aspera/nodes",
    })
    return response.data || []
}

export class AsperaApi {
    static createLocalUser = createLocalUser
    static listLocalUsers = listLocalUsers
    // static removeLocalUser = removeLocalUser
    static attachShares = attachShares
    static detachShare = detachShare
    static createShare = createShare
    static listShares = listShares
    static removeShare = removeShare
    static createPermission = createPermission
    static listPermissions = listPermissions
    static removePermission = removePermission
    static listNodes = listNodes
}
