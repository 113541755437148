import React, {useEffect, useState} from "react"
import {TableCell, TableRow} from "@mui/material"
import {Score} from "./ScoreComponent"
import {SpeedtestUser} from "../query/user.query"
import {getDistance, IspInfo} from "../types/IspInfo"
import {ScoreLevel} from "../types/ScoreLevel"
import AddressInfoDialog from "./AddressInfoDialog"
import InfoIcon from "@mui/icons-material/Info"

type TestLineProps = {
    testResults: SpeedtestUser
}

const scores: ScoreLevel[] = ["fail", "warn", "pass"]
const revScores = [...scores].reverse()

function grade(
    value: string,
    marks: number[],
    scores: ScoreLevel[],
): ScoreLevel | null {
    if (!value) return null

    const numericValue = parseFloat(value)
    const i = marks.findIndex(mark => numericValue <= mark)

    return scores[i] || null
}

function calculateScore(user: SpeedtestUser) {
    return {
        dl: grade(user.dl, [40, 50], scores),
        ul: grade(user.ul, [2, 5], scores),
        ping: grade(user.ping, [50, 95], revScores),
    }
}

function calculateScoreTotal(user: SpeedtestUser): ScoreLevel {
    const score = calculateScore(user)
    const {dl, ul, ping} = score

    for (const s of scores) {
        if ([dl, ul, ping].includes(s)) {
            return s
        }
    }
    return 'pass'
}

export const TestLine: React.FC<TestLineProps> = (props) => {
    const {testResults} = props
    const [dialogOpen, setDialogOpen] = useState(false)
    const [ispDetail, setIspDetail] = useState<IspInfo | null>(null)

    useEffect(() => {
        const ispInfo = testResults.ispinfo
            ? JSON.parse(testResults.ispinfo)
            : null
        setIspDetail(ispInfo)
    }, [testResults.ispinfo])

    const showIspDetail = (): void => {
        setDialogOpen(true)
    }

    const score = calculateScore(testResults)
    const scoreTotal = calculateScoreTotal(testResults)

    const addressInfoDialog = (
        ispDetail
            ? <AddressInfoDialog open={dialogOpen}
                                 onClose={() => setDialogOpen(false)}
                                 ispDetail={ispDetail}
                                 testResults={testResults}
            />
            : null
    )

    return (
        <>
            {addressInfoDialog}
            <TableRow hover={true}>
                <TableCell>
                    {testResults.siteId}
                </TableCell>
                <TableCell>
                    <span>{testResults.ip}</span>
                    &nbsp;&nbsp;
                    {testResults.ispinfo && (
                        <InfoIcon
                            onClick={() => showIspDetail()}
                            fontSize="small"
                            style={{verticalAlign: "middle", cursor: "pointer"}}
                        />
                    )}
                </TableCell>
                <TableCell>{testResults.timestamp}</TableCell>
                <TableCell>{getDistance(ispDetail)} mi</TableCell>
                <TableCell>
                    <Score value={scoreTotal} score={scoreTotal}/>
                </TableCell>
                <TableCell align="right">
                    <Score value={testResults.dl} score={score.dl}/>
                </TableCell>
                <TableCell align="right">
                    <Score value={testResults.ul} score={score.ul}/>
                </TableCell>
                <TableCell align="right">
                    <Score value={testResults.ping} score={score.ping}/>
                </TableCell>
            </TableRow>
        </>
    )
}