import React, {FC, useEffect, useState} from "react"
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography} from "@mui/material"

type TProps = {
    title: string,
    message: string,
    open: boolean,
    recordName: string,
    onCancel: () => void,
    onAccept: () => void,
}

const DeleteShareConfirmDialog: FC<TProps> = (props) => {
    const {
        title,
        message,
        open,
        recordName,
        onCancel,
        onAccept,
    } = props

    const [inputValue, setInputValue] = useState("")
    useEffect(() => setInputValue(""), [open])

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value)
    }

    const isInputValid = inputValue === recordName

    return (
        <Dialog open={open}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Stack gap={1}>
                    {message}
                    <Typography variant={"h5"}>
                        {recordName}
                    </Typography>
                    <TextField
                        autoFocus
                        margin="dense"
                        fullWidth
                        value={inputValue}
                        onChange={handleInputChange}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>
                    Cancel
                </Button>
                <Button onClick={onAccept} disabled={!isInputValid}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteShareConfirmDialog