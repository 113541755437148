import {FC} from "react"
import AppPage from "@components/AppPage"
import ProjectSidebar from "./ProjectSidebar"
import {DataProvider} from "../context/DataProvider"
import {Outlet, useParams} from "react-router-dom"
import PageContent from "@components/PageContent"

const ProjectPage: FC = () => {
    const {projectId} = useParams<{ projectId: string }>()

    if (!projectId) return null

    return (
        <AppPage>
            <DataProvider projectId={projectId}>
                <ProjectSidebar/>
                <PageContent>
                    <Outlet/>
                </PageContent>
            </DataProvider>
        </AppPage>
    )
}

export default ProjectPage