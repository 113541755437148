import {FC} from "react"
import {Button} from "@mui/material"

type TProps = {
    disabled?: boolean,
    label: string,
    icon?: any,
    onClick: () => void,
}

const BaseFeatureButton: FC<TProps> = (props) => {
    const {disabled, label, icon, onClick} = props

    return (
        <Button
            onClick={() => onClick()}
            disabled={disabled}
            variant="contained"
            color="primary"
            startIcon={icon}
        >
            {label}
        </Button>
    )
}

export default BaseFeatureButton