import {FC, useCallback, useEffect, useState} from "react"
import {Drive, GoogleApi} from "@api/google"
import {AddCircle, Cancel, OpenInNew} from "@mui/icons-material"
import {useAlert} from "@context/AlertContext"
import {Feature, useFeature} from "./hooks/useFeature"
import BaseFeatureRow from "../BaseFeatureRow"
import BaseFeatureButton from "../BaseFeatureButton"
import {Stack} from "@mui/material"
import {useConfirmationDialog} from "@hooks/useConfirmationDialog"

type TProps = {
    driveName: string,
}

const GoogleDrive: FC<TProps> = ({driveName}) => {
    const [drive, setDrive] = useState<Drive | null>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const {showAlert} = useAlert()
    const {ftCheck} = useFeature()
    const {openDialog, ConfirmationDialog} = useConfirmationDialog()

    const allowManage = ftCheck(Feature.MANAGE_DRIVE)

    useEffect(() => {
        setLoading(true)
        GoogleApi.getDrive(driveName)
            .then(setDrive)
            .catch(error => showAlert(error.message, "error"))
            .finally(() => setLoading(false))
    }, [showAlert, driveName])

    const handleCreate = useCallback(() => {
        setLoading(true)
        GoogleApi.createDrive(driveName)
            .then(setDrive)
            .catch(error => showAlert(error.message, "error"))
            .finally(() => setLoading(false))
    }, [driveName, showAlert])

    const handleDelete = useCallback(() => {
        setLoading(true)
        GoogleApi.deleteDrive(driveName)
            .then(() => setDrive(null))
            .catch(error => showAlert(error.message, "error"))
            .finally(() => setLoading(false))
    }, [driveName, showAlert])

    let buttons = []
    if (allowManage) {
        buttons.push(
            <BaseFeatureButton
                key={"create"}
                disabled={loading || !!drive}
                label={"Create"}
                icon={<AddCircle/>}
                onClick={() => {
                    openDialog({
                        title: "Create Google Drive",
                        message: `Are you sure you want to create a Google Drive for project ${driveName}?`,
                        onAccept: handleCreate,
                    })
                }}
            />,
        )
        buttons.push(
            <BaseFeatureButton
                key={"delete"}
                disabled={true} // loading || !drive}
                label={"Delete"}
                icon={<Cancel/>}
                onClick={() => {
                    openDialog({
                        title: "Archive Google Drive",
                        message: `Are you sure you want to archive the Google Drive for project ${driveName}?`,
                        onAccept: handleDelete,
                    })
                }}
            />,
        )
    }
    if (drive) {
        buttons.push(
            <BaseFeatureButton
                key={"open"}
                disabled={false}
                label={"Open"}
                icon={<OpenInNew/>}
                onClick={() => (
                    GoogleApi.joinDrive(driveName)
                        .then(() => window.open(drive.url, '_blank'))
                )}
            />,
        )
    }

    return (
        <>
            <ConfirmationDialog/>
            <BaseFeatureRow
                title={"Google Drive"}
                loading={loading}
                isActive={!!drive}
            >
                <Stack direction="row" gap={1}>
                    {buttons}
                </Stack>
            </BaseFeatureRow>
        </>
    )
}

export default GoogleDrive