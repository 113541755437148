import {Project, ProjectResults, Query} from "../query/project.query"
import {useGenericQuery} from "@hooks/useGenericQueryHook"

export const useProjectData = (projectId: string) => {
    const {result, loading, error} = useGenericQuery<ProjectResults, Project>({
        query: Query,
        resourceKey: "resources",
        resultSelector: data => data?.projects.results[0],
    }, {projectId})

    return {result, loading, error}
}