import {useLazyQuery} from "@apollo/client"
import {useApolloClient} from "@hooks/useApolloClient"
import useAuth from "@hooks/useAuth"
import useLogout from "@hooks/useLogout"
import Logout from "@mui/icons-material/Logout"
import SecurityIcon from '@mui/icons-material/Security'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import {
    Avatar,
    Box,
    Divider,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Skeleton,
    Stack,
    Typography,
} from "@mui/material"
import React, {useEffect, useState} from "react"
import {useNavigate} from "react-router"
import {Result, UserQuery} from "../query/user.query"
import {Feature, useFeatureApp} from "../hooks/useFeatureApp"
import {useAlert} from "@context/AlertContext"
import PersonSelectorDialog from "@components/dialogs/PersonSelector"
import {OAuthApi} from "@api/oauth"

const AccountMenu = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const {user, setAuth} = useAuth()
    const logout = useLogout()
    const navigate = useNavigate()
    const client = useApolloClient("resources")
    const {showAlert} = useAlert()
    const [showImpersonate, setShowImpersonate] = useState(false)

    const {ftCheck} = useFeatureApp()

    const [list, {data}] = useLazyQuery<Result>(
        UserQuery, {
            client,
            errorPolicy: "all",
            notifyOnNetworkStatusChange: true,
        },
    )

    useEffect(() => {
        if (user?.id) {
            list({variables: {id: [user.id]}}).then()
        }
    }, [user, list])

    const userData = data?.users.results[0]

    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const allowImpersonate = ftCheck(Feature.IMPERSONATE)

    const firstLetter = user?.accountId[0].toUpperCase()
    const profileImage = userData?.avatar_sm

    const avatar = userData
        ? profileImage
            ? <Avatar sx={{width: 32, height: 32}} src={profileImage}/>
            : <Avatar sx={{width: 32, height: 32}}>{firstLetter}</Avatar>
        : <Skeleton width={32} height={32} variant="circular" animation={"pulse"}/>

    const impersonateDialog = showImpersonate
        ? <PersonSelectorDialog
            open={showImpersonate}
            onClose={() => setShowImpersonate(false)}
            onSelect={(userIds) => {
                OAuthApi.impersonateUser(userIds[0])
                    .then(accessToken => {
                        setAuth({accessToken})
                        window.location.reload()
                    })
                    .catch(error => showAlert(error.message, "error"))
            }}/>
        : null

    return (
        <>
            {impersonateDialog}
            <Stack alignItems="flex-end" width="auto">
                <Typography>
                    {userData?.display_name || ""}
                </Typography>
                <Typography variant="subtitle2" align="right" color={"grey.400"}>
                    {userData ? user?.role ? `${user.role}` : "" : null}
                </Typography>
            </Stack>
            <Box sx={{display: "flex", alignItems: "center", textAlign: "center"}}>
                <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ml: 2}}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                >
                    {avatar}
                </IconButton>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                slotProps={{
                    paper: {
                        elevation: 1,
                        sx: {
                            overflow: "visible",
                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                            mt: 1.5,
                            "& .MuiAvatar-root": {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            "&::before": {
                                content: "\"\"",
                                display: "block",
                                position: "absolute",
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: "background.paper",
                                transform: "translateY(-50%) rotate(45deg)",
                                zIndex: 0,
                            },
                        },
                    },
                }}
                transformOrigin={{horizontal: "right", vertical: "top"}}
                anchorOrigin={{horizontal: "right", vertical: "bottom"}}
            >
                {allowImpersonate && (
                    <>
                        <MenuItem onClick={() => setShowImpersonate(true)}>
                            <ListItemIcon>
                                <SecurityIcon fontSize="small"/>
                            </ListItemIcon>
                            Impersonate
                        </MenuItem>
                        <Divider/>
                    </>
                )}
                <MenuItem
                    onClick={() => window.open("https://psyop.atlassian.net/servicedesk/customer/portal/1", "_blank")}>
                    <ListItemIcon>
                        <HelpOutlineIcon fontSize="small"/>
                    </ListItemIcon>
                    Tech Support
                </MenuItem>
                <MenuItem
                    onClick={() => window.open("https://wiki-prd.psyop.tv/en/public/applications/internal/psyop-io", "_blank")}>
                    <ListItemIcon>
                        <HelpOutlineIcon fontSize="small"/>
                    </ListItemIcon>
                    Documentation
                </MenuItem>
                <Divider/>
                <MenuItem onClick={() => logout().then(() => navigate("/"))}>
                    <ListItemIcon>
                        <Logout fontSize="small"/>
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </>
    )
}

export default AccountMenu