import {gql} from "@apollo/client"

export type Result = {
    users: {
        results: {
            display_name: string
            avatar_sm: string
        }[]
    }
}

export const UserQuery = gql`
    query ($id: [String!] = "") {
        users(filters: {id: $id}) {
            results {
                display_name
                avatar_sm
            }
        }
    }
`

