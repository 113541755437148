import {FC} from "react"
import {Outlet} from "react-router-dom"
import useAuth from "@hooks/useAuth"
import {Role} from "@utils/roles"
import MissingPage from "../pages/MissingPage"

type TPropsRequireAuth = {
    roles: Role[]
}

const RequireAuth: FC<TPropsRequireAuth> = (props) => {
    const {roles} = props
    const {user} = useAuth()

    return (
        user?.role && roles.includes(user.role)
            ? <Outlet/>
            : <MissingPage/>
    )
}

export default RequireAuth