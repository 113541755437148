import React, {createContext, FC, PropsWithChildren, useContext} from "react"
import {Project} from "./query/project.query"
import {useProjectData} from "./hooks/useProjectData"

type TDataContext = {
    project: Project | null,
}

export const DataContext = createContext<TDataContext>({
    project: null,
})

type TPropsDataProvider = {
    projectId: string,
} & PropsWithChildren

export const DataProvider: FC<TPropsDataProvider> = (props) => {
    const {projectId, children} = props
    const {result: project} = useProjectData(projectId)

    if (!project) return null

    return (
        <DataContext.Provider value={{
            project,
        }}>
            {children}
        </DataContext.Provider>
    )
}

export const useDataContext = () => {
    return useContext(DataContext)
}

export type {Project}
