export const HEADER_ACCESS_TOKEN: string = "X-Access-Token"
export const HEADER_SESSION_ID: string = "X-Session-Id"
export const COOKIE_SESSION_ID: string = "session_id"
export const INTERVAL_TOKEN_CHECK: number = 1000

export const HEADER_HEIGHT = 48

export const COLORS = {
    blue: {
        light: "#0070ff",
        medium: "#0050cc",
        dark: "#003399"
    },
    green: {
        light: "#00a900",
        medium: "#008000",
        dark: "#006400"
    },
    orange: {
        light: "#e78300",
        medium: "#c06000",
        dark: "#984b00"
    },
    red: {
        light: "#ff0000",
        medium: "#cc0000",
        dark: "#990000"
    }
}
