import {AsperaApi, AsperaPermission, AsperaUser} from "@api/aspera"
import React, {FC, useState} from "react"
import {useAlert} from "@context/AlertContext"
import {useAsperaContext} from "../../../context/DataProvider"
import dayjs from "dayjs"
import ConfirmationDialog from "@components/dialogs/ConfirmationDialog"
import {IconButton, Stack, Typography} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import {Feature, useFeatureAsperaPage} from "../../../hooks/useFeatureAsperaPage"

type TPermissionRowProps = {
    permission: AsperaPermission
    user: AsperaUser
    onChange: () => void
}

export const PermissionRow: FC<TPermissionRowProps> = (props) => {
    const {permission, user, onChange} = props

    const {showAlert} = useAlert()
    const [removeUser, setRemoveUser] = useState<number | null>(null)
    const {getUser} = useAsperaContext()
    const {ftCheck} = useFeatureAsperaPage()

    const allowManageAssignments = ftCheck(Feature.MANAGE_ASSIGNMENTS)
    const permissionAdded = dayjs(permission.created_at).format("YYYY-MM-DD")
    const fullName = `${user.first_name} ${user.last_name}`

    const handleRemoveUser = (userId: number) => {
        setRemoveUser(userId)
    }

    const deletePermissionConfirmDialog = (
        !!removeUser
            ? (() => {
                const user = getUser(removeUser)
                const fullName = `${user.first_name} ${user.last_name}`
                return (
                    <ConfirmationDialog
                        title={"Remove User"}
                        message={`Are you sure you want to remove ${fullName} from the share?`}
                        open={!!removeUser}
                        onCancel={() => setRemoveUser(null)}
                        onAccept={() => {
                            AsperaApi.removePermission(permission.share_id, removeUser)
                                .then(() => onChange())
                                .catch((err) => showAlert(`Failed to remove user - ${err.message}`, "error"))
                                .finally(() => setRemoveUser(null))
                        }}
                    />
                )
            })()
            : null
    )

    return (
        <>
            {deletePermissionConfirmDialog}
            <Stack key={permission.authorizable_id}
                   gap={1}
                   direction={"row"}
                   alignItems={"center"}>
                {
                    allowManageAssignments
                        ? (
                            <IconButton size="small" onClick={() => handleRemoveUser(permission.authorizable_id)}>
                                <CloseIcon color={"error"} fontSize="small"/>
                            </IconButton>
                        )
                        : null
                }
                <Typography width={"150px"}>{fullName}</Typography>
                <Typography width={"200px"} variant={"body2"} color={"grey"}>{user.email}</Typography>
                <Typography width={"100px"} variant={"body2"} color={"grey"}>{user.username}</Typography>
                <Typography width={"100px"} variant={"body2"} color={"grey"}>Added: {permissionAdded}</Typography>
            </Stack>
        </>
    )
}

