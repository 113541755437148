import {AuthUsersResults, MinimalAuthUser, UsersQuery} from "../query/users.query"
import {useGenericQuery} from "@hooks/useGenericQueryHook"

export const useSpeedTestUsers = () => {
    const {result, loading, error, refetch} = useGenericQuery<AuthUsersResults, MinimalAuthUser[]>({
        query: UsersQuery,
        resourceKey: "speedtest",
        resultSelector: data => data?.authUser,
    })

    return {result, loading, error, refetch}
}

export type {MinimalAuthUser}