import {useAlert} from "@context/AlertContext"
import {useEffect, useState} from "react"
import {AsperaApi, AsperaUser} from "@api/aspera"

export const useAsperaUserData = () => {
    const [loading, setLoading] = useState<boolean>(true)
    const [users, setUsers] = useState<AsperaUser[]>([])
    const {showAlert} = useAlert()

    useEffect(() => {
        setLoading(true)
        AsperaApi.listLocalUsers()
            .then((users) => {
                setUsers(users.sort((a, b) => (`${a.first_name} ${a.last_name}`).localeCompare((`${b.first_name} ${b.last_name}`))))
            })
            .catch((error) => showAlert(error.message, "error"))
            .finally(() => setLoading(false))
    }, [showAlert])

    return {loading, users}
}