import React, {FC, useCallback, useEffect, useState} from "react"
import {Box, CircularProgress, Grid, IconButton, Stack, Typography} from "@mui/material"
import {AsperaApi, AsperaPermission, AsperaShare} from "@api/aspera"
import {useAsperaContext} from "../../context/DataProvider"
import dayjs from "dayjs"
import Paper from "@mui/material/Paper"
import AsperaUserSelector from "@components/dialogs/AsperaUserSelector"
import {useAlert} from "@context/AlertContext"
import ConfirmationDialog from "@components/dialogs/ConfirmationDialog"
import DeleteIcon from '@mui/icons-material/Delete'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import AppTooltip from "@components/AppTooltip"
import {PermissionRow} from "./components/PermissionRow"
import LinkOffIcon from '@mui/icons-material/LinkOff'
import DeleteShareConfirmDialog from "./components/DeleteShareConfirmDialog"
import {Feature, useFeatureAsperaPage} from "../../hooks/useFeatureAsperaPage"

type TProps = {
    projectId?: string
    asperaShare: AsperaShare
    onShareRemove: (shareId: number) => void
}

export const AsperaShareRow: FC<TProps> = (props) => {
    const {projectId, asperaShare, onShareRemove} = props

    const [loading, setLoading] = useState<boolean>(false)
    const [userSelectorOpen, setUserSelectorOpen] = useState<boolean>(false)
    const [showRemoveShare, setShowRemoveShare] = useState<boolean>(false)
    const [showDetachShare, setShowDetachShare] = useState<boolean>(false)

    const {ftCheck} = useFeatureAsperaPage()
    const {getUser} = useAsperaContext()
    const [permissions, setPermissions] = useState<AsperaPermission[]>([])
    const {showAlert} = useAlert()

    const allowManageAssignments = ftCheck(Feature.MANAGE_ASSIGNMENTS)
    const allowManageShares = ftCheck(Feature.MANAGE_SHARES)

    const updatePermissions = useCallback(() => {
        setLoading(true)
        return AsperaApi
            .listPermissions(asperaShare.id)
            .then(setPermissions)
            .finally(() => setLoading(false))
    }, [setLoading, setPermissions, asperaShare])

    useEffect(() => {
        updatePermissions()
            .catch((err) => showAlert(`Failed to load permissions - ${err.message}`, "error"))
    }, [showAlert, updatePermissions])

    const handleAddUsers = (userIds: number[]) => {
        userIds.forEach(userId => {
            AsperaApi.createPermission(asperaShare.id, userId)
                .then(() => updatePermissions())
                .catch((err) => showAlert(`Failed to add user - ${err.message}`, "error"))
        })
        updatePermissions().then()
    }

    const handleDeleteShare = () => {
        setShowRemoveShare(true)
    }

    const handleDetachShare = () => {
        setShowDetachShare(true)
    }

    const detachShareConfirmDialog = (
        projectId && <ConfirmationDialog
            title={"Detach Share"}
            message={`Are you sure you want to detach the share?`}
            open={showDetachShare}
            onCancel={() => setShowDetachShare(false)}
            onAccept={() => {
                AsperaApi.detachShare(projectId, asperaShare.id)
                    .then(() => showAlert("Share detached successfully", "success"))
                    .then(() => onShareRemove(asperaShare.id))
                    .catch((err) => showAlert(`Failed to detach share - ${err.message}`, "error"))
                    .finally(() => setShowDetachShare(false))
            }}
        />
    )

    const deleteShareConfirmDialog = (
        <DeleteShareConfirmDialog
            recordName={asperaShare.name}
            title={"Delete Share"}
            message={`Enter the name of the share to delete it.`}
            open={showRemoveShare}
            onCancel={() => setShowRemoveShare(false)}
            onAccept={() => {
                AsperaApi.removeShare(asperaShare.id)
                    .then(() => showAlert("Share deleted successfully", "success"))
                    .then(() => onShareRemove(asperaShare.id))
                    .catch((err) => showAlert(`Failed to delete share - ${err.message}`, "error"))
                    .finally(() => setShowRemoveShare(false))
            }}
        />
    )

    const userDialog = (
        userSelectorOpen
            ? <AsperaUserSelector open={userSelectorOpen}
                                  onClose={() => setUserSelectorOpen(false)}
                                  onSelect={
                                      (userId) => {
                                          handleAddUsers(userId)
                                          setUserSelectorOpen(false)
                                      }
                                  }/>
            : null
    )

    const userList = (
        permissions
            .map(permission => ({
                permission: permission,
                user: getUser(permission.authorizable_id),
            }))
            .filter(({user}) => !!user)
            .sort((a, b) => (a.user.first_name || "").localeCompare(b.user.first_name || ""))
            .map(({permission, user}) => {
                return (
                    <PermissionRow key={permission.authorizable_id}
                                   onChange={updatePermissions}
                                   permission={permission}
                                   user={user}/>
                )
            })
    )

    const createdAt = dayjs(asperaShare.created_at)

    return (
        <>
            {userDialog}
            {deleteShareConfirmDialog}
            {detachShareConfirmDialog}
            <Box sx={{borderTop: "1px solid #555", borderBottom: "1px solid #555"}}>
                <Paper sx={{p: 2, borderBottom: "1px solid #555"}}>
                    <Grid container alignItems={"center"}>
                        <Grid item xs>
                            <Typography variant="body1"
                                        fontSize={18}
                                        color={"text.primary"}>
                                {asperaShare.name}
                            </Typography>
                        </Grid>
                        <Stack direction={"row"} gap={4} alignItems={"center"}>
                            <Typography variant="caption">
                                Created: {createdAt.format("YYYY-MM-DD")}
                            </Typography>
                            <Stack direction={"row"} gap={1}>
                                {
                                    allowManageAssignments
                                        ? (
                                            <AppTooltip title={"Add User"} placement={"top"}>
                                                <IconButton size="medium"
                                                            onClick={() => setUserSelectorOpen(true)}>
                                                    <GroupAddIcon fontSize="medium"/>
                                                </IconButton>
                                            </AppTooltip>
                                        )
                                        : null
                                }
                                {
                                    allowManageShares
                                        ? (
                                            <>
                                                <AppTooltip title={"Detach Share"} placement={"top"}>
                                                    <IconButton size="medium"
                                                                onClick={() => handleDetachShare()}>
                                                        <LinkOffIcon fontSize="medium"/>
                                                    </IconButton>
                                                </AppTooltip>
                                                <AppTooltip title={"Delete Share"} placement={"top"}>
                                                    <IconButton size="medium"
                                                                color={"error"}
                                                                onClick={() => handleDeleteShare()}>
                                                        <DeleteIcon fontSize="medium"/>
                                                    </IconButton>
                                                </AppTooltip>
                                            </>
                                        )
                                        : null
                                }
                            </Stack>
                        </Stack>
                    </Grid>
                </Paper>
                <Box sx={{margin: 1}}>
                    {loading ? <CircularProgress/> : userList}
                </Box>
            </Box>
        </>
    )
}