import React, {FC, useCallback, useEffect, useState} from "react"
import {SlackApi, SlackChannel} from "@api/slack"
import {Grid, IconButton, Stack} from "@mui/material"
import {SlackDataProvider} from "./context/DataProvider"
import {SlackChannelRow} from "./components/SlackRow"
import {useAlert} from "@context/AlertContext"
import {useDataContext} from "../../context/DataProvider"
import AppTooltip from "@components/AppTooltip"
import {Feature, useFeatureSlackPage} from "./hooks/useFeatureSlackPage"
import {PromptDialog} from "@components/dialogs/PromptDialog"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

const ProjectSlackComponent: FC = () => {
    const {project} = useDataContext()
    const [channels, setChannels] = useState<SlackChannel[]>([])
    const [showChannelCreate, setShowChannelCreate] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const {showAlert} = useAlert()
    const {ftCheck} = useFeatureSlackPage()

    const channelName = `p-${project?.name}`
    const channelPattern = `${channelName}*`

    const refetchChannels = useCallback(() => {
        if (!project) return
        SlackApi.listChannels(channelPattern)
            .then(setChannels)
    }, [project])

    useEffect(() => refetchChannels(), [refetchChannels])

    const allowCreateChannel = ftCheck(Feature.CREATE_CHANNEL)

    const createChannelDialog = (
        <PromptDialog
            title="Create Channel"
            message="Sub Channel Label (leave blank for default)"
            label={"Ex: scheduling, comp, etc."}
            onAccept={(input) => {
                const newName = input === "" ? undefined : `${channelName}-${input}`
                handleCreate(newName)
            }}
            isOpen={showChannelCreate}
            handleClose={() => setShowChannelCreate(false)}
            pattern={new RegExp(`^[a-z0-9-_]+$`)}
        />
    )

    const handleCreate = useCallback((newName?: string) => {
        setLoading(true)
        SlackApi.createChannel(newName || channelName, true)
            .then(() => refetchChannels())
            .catch(error => showAlert(error.message, "error"))
            .finally(() => setLoading(false))
    }, [channelName, showAlert])

    return (
        <>
            {createChannelDialog}
            <Grid container
                  gap={4}
                  justifyContent={"flex-start"}
                  alignContent={"flex-start"}>
                <SlackDataProvider>
                    <Stack gap={4} width={"100%"}>
                        <Stack direction={"row"} gap={1} justifyContent={"flex-end"}>
                            {
                                allowCreateChannel
                                    ? (
                                        <AppTooltip title={"Create Channel"} placement={"top"}>
                                            <IconButton size="large"
                                                        onClick={() => setShowChannelCreate(true)}>
                                                <AddCircleOutlineIcon fontSize="large"/>
                                            </IconButton>
                                        </AppTooltip>
                                    )
                                    : null
                            }
                        </Stack>
                        {channels
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(channel => (
                                <SlackChannelRow key={channel.id}
                                                 channel={channel}
                                                 refetch={refetchChannels}
                                />
                            ))}
                    </Stack>
                </SlackDataProvider>
            </Grid>
        </>
    )
}

const ProjectSlackPage: FC = () => {
    return (
        <SlackDataProvider>
            <ProjectSlackComponent/>
        </SlackDataProvider>
    )
}

export default ProjectSlackPage