export type IspInfo = {
    processedString: string
    rawIspInfo: {
        ip: string
        hostname: string
        city: string
        region: string
        country: string
        loc: string
        org: string
        postal: string
        timezone: string
        readme: string
    }
}

export const getDistance = (ispInfo: IspInfo | null): string => {
    if (!ispInfo) return ""
    const distance = ispInfo.processedString.match(/\((.*) mi\)/)?.[1]
    return distance || ""
}