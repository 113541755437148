import useAuth from "@hooks/useAuth"
import {Role} from "@utils/roles"
import {useCallback} from "react"

export const useBaseFeature = <T extends string>(FEATURES: Record<T, Set<Role>>) => {
    const {user} = useAuth()

    const ftCheck = useCallback((ft: T) => {
        return FEATURES[ft].has(user?.role as Role || Role.Default) || false
    }, [FEATURES, user?.role])

    return {ftCheck}
}