import React, {FC, useState} from "react"
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    TextField,
} from "@mui/material"
import {AsperaApi, AsperaUser} from "@api/aspera"
import {useAlert} from "@context/AlertContext"

type TProps = {
    open: boolean
    onClose: () => void
    onCreate: (user: AsperaUser) => void
}

const AsperaUserCreateDialog: FC<TProps> = ({open, onClose, onCreate}) => {
    const [username, setUsername] = useState("")
    const [email, setEmail] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [sendWelcomeEmail, setSendWelcomeEmail] = useState(true)
    const [emailError, setEmailError] = useState(false)
    const {showAlert} = useAlert()

    const handleCreate = async () => {
        if (!validateEmail(email)) {
            setEmailError(true)
            return
        }

        setEmailError(false)

        try {
            const newUser = await AsperaApi.createLocalUser(
                username,
                email,
                firstName,
                lastName,
                sendWelcomeEmail,
            )
            onCreate(newUser)
            onClose()
        } catch (error) {
            showAlert(`${error}`, "error")
        }
    }

    const validateEmail = (email: string) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return re.test(email)
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Create New User</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Username"
                    type="text"
                    fullWidth
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Email Address"
                    type="email"
                    fullWidth
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={emailError}
                    helperText={emailError ? "Invalid email address" : ""}
                />
                <TextField
                    margin="dense"
                    label="First Name"
                    type="text"
                    fullWidth
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Last Name"
                    type="text"
                    fullWidth
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={sendWelcomeEmail}
                            onChange={(e) => setSendWelcomeEmail(e.target.checked)}
                        />
                    }
                    label="Send Welcome Email"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleCreate} color="primary">
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AsperaUserCreateDialog