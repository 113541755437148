import {gql} from "@apollo/client"

export type PersonResults = {
    users: {
        results: Person[]
    }
}

export type Person = {
    id: string
    name: string
    avatar_sm: string
    display_name: string
}

export const Query = gql`
    query {
        users(filters: {active: true}) {
            results {
                id
                name
                display_name
                avatar_sm
            }
        }
    }
`