import {SessionUser} from "@type/user"
import {HEADER_ACCESS_TOKEN, HEADER_SESSION_ID} from "@utils/constants"
import {getSessionAuth, getSessionId, setSessionId} from "@utils/session"

type TPropsAuthorize = {
    searchParams: URLSearchParams,
}

const impersonateUser = async (resUserId: string): Promise<string> => {
    const auth = getSessionAuth()
    if (!auth) {
        throw new Error("Not authorized")
    }

    return fetch("/api/v1/oauth/impersonate", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${auth.accessToken}`,
        },
        body: JSON.stringify({res_user_id: resUserId}),
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error("Failed to impersonate")
            }
            const access_token = response.headers.get(HEADER_ACCESS_TOKEN)
            if (!access_token) {
                throw new Error("Failed to get access token")
            }
            return access_token
        })
        .catch((error) => {
            throw new Error(`Failed to impersonate - ${error}`)
        })
}

const currentUser = async (): Promise<SessionUser> => {
    const auth = getSessionAuth()

    if (!auth) {
        throw new Error("Not authorized")
    }

    return fetch("/api/v1/oauth/me", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${auth.accessToken}`,
        },
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`Failed to get current user - ${response.statusText}`)
            }
            return response.json()
        })
        .then((data: { id: string, account_id: string, role: string, display_name: string }) => {
            return {
                id: data.id,
                accountId: data.account_id,
                role: data.role,
                displayName: data.display_name,
            } as SessionUser
        })
        .catch((error) => {
            throw new Error(`Failed to get current user - ${error}`)
        })
}

const loginAzure = async (): Promise<string> => {
    const queryParams = new URLSearchParams({
        callback_uri: `${window.location.origin}/authorize`,
    })

    return fetch(`/api/v1/oauth/azure?${queryParams.toString()}`, {
        method: "GET",
        // credentials: "include",  // do not enable, causes CORS failure
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error("Failed to login")
            }
            const session_id = response.headers.get(HEADER_SESSION_ID)
            if (!session_id) {
                throw new Error("Failed to get session id")
            }
            setSessionId(session_id)
            return response.json()
        })
        .then((data: { auth_uri: string }) => {
            return data.auth_uri
        })
        .catch((error) => {
            throw new Error(`Failed to login - ${error}`)
        })
}

const authorizeLogin = async (props: TPropsAuthorize): Promise<string> => {
    return fetch(`/api/v1/oauth/authorize?${props.searchParams}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            [HEADER_SESSION_ID]: getSessionId(),
        },
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error("Failed to authorize")
            }
            const access_token = response.headers.get(HEADER_ACCESS_TOKEN)
            if (!access_token) {
                throw new Error("Failed to get access token")
            }
            return access_token
        })
}

const refreshLogin = async (accessToken?: string): Promise<string | null> => {
    return fetch("/api/v1/oauth/refresh", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`,
        },
    })
        .then((response) => {
            if (response.status >= 500) {
                // Server error, do nothing, let token expire
                throw new Error("Failed to refresh")
            }
            if (!response.ok) {
                // Client error, likely invalid token, clear
                return null
            }
            const access_token = response.headers.get(HEADER_ACCESS_TOKEN)
            if (!access_token) {
                return null
            }
            return access_token
        })
}

export class OAuthApi {
    static impersonateUser = impersonateUser
    static currentUser = currentUser
    static loginAzure = loginAzure
    static authorizeLogin = authorizeLogin
    static refreshLogin = refreshLogin
}