import React, {FC, useState} from "react"
import {
    Button,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material"
import {Site, SITES} from "../constants"
import {TestLine} from "../components/TestLineComponent"
import {useSpeedTestUser} from "../hooks/useSpeedTestUser"
import {useParams} from "react-router-dom"
import {sendEmail} from "../utils"
import AppPage from "@components/AppPage"
import PageContent from "@components/PageContent"
import {SpeedTestApi} from "@api/speedtest"
import {FullAuthUser} from "../query/user.query"
import {useAlert} from "@context/AlertContext"

export const UserDetailPage: FC = () => {
    const {userId} = useParams<{ userId: string }>()

    const [site, setSite] = useState<Site | null>(null)
    const [sendLabel, setSendLabel] = useState("Send Email")
    const {result: user} = useSpeedTestUser(parseInt(userId!))
    const {showAlert} = useAlert()

    const handleChange = (siteId: number): void => {
        const selectedSite = SITES.find((s) => s.code === siteId)
        if (selectedSite) {
            setSite(selectedSite)
        }
    }

    const handleSendEmail = (user: FullAuthUser, selectedSite: Site) => {
        sendEmail(selectedSite, user)
            .then(() => {
                showAlert("Email sent.", "success")
                SpeedTestApi.renewUser(user.id).then()
            })
    }

    if (!user) {
        return <AppPage><CircularProgress/></AppPage>
    }

    return (
        <AppPage>
            <PageContent>
                <Paper square sx={{p: 2}}>
                    <Grid container>
                        <Grid item xs={4}>
                            <h3>{user.id}</h3>
                            <div><em>Email: {user.email}</em></div>
                            <div><em>Expires: {user.expires}</em></div>
                            {
                                user.speedtestUsers.length > 0
                                    ? <div><em>Tests: {user.speedtestUsers.length}</em></div>
                                    : <div><em>No test results available</em></div>
                            }
                        </Grid>
                        <Grid item xs={4}>
                            <h3>Send Invitation to Test</h3>
                            <FormControl style={{minWidth: "30vw"}}>
                                <InputLabel>Site</InputLabel>
                                <Select
                                    labelId="site"
                                    id="site"
                                    value={site ? site.code : ""}
                                    onChange={(event) => {
                                        setSendLabel("Send Email")
                                        handleChange(event.target.value as number)
                                    }}
                                >
                                    {
                                        SITES.map((site) => (
                                            <MenuItem key={site.code}
                                                      value={site.code}>
                                                {site.name}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                            <FormControl style={{marginTop: 30}}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    disabled={!Boolean(site)}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        if (site) {
                                            handleSendEmail(user, site)
                                        }
                                    }}
                                >
                                    {sendLabel}
                                </Button>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Paper>

                {user.speedtestUsers.length > 0 && (<TableContainer style={{maxHeight: "90vh"}}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Site</TableCell>
                                <TableCell>IP</TableCell>
                                <TableCell>When</TableCell>
                                <TableCell>Distance</TableCell>
                                <TableCell>Grade</TableCell>
                                <TableCell align="right">DL (Mbps)</TableCell>
                                <TableCell align="right">UL (Mbps)</TableCell>
                                <TableCell align="right">Ping (ms)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                user.speedtestUsers.map((testResults) => (
                                    <TestLine key={testResults.id}
                                              testResults={testResults}/>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>)}
            </PageContent>
        </AppPage>
    )
}

