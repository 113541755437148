import React, {FC, useCallback, useEffect, useState} from "react"
import {Box, CircularProgress, FormControlLabel, Grid, IconButton, Stack, Switch, Typography} from "@mui/material"
import {SlackApi, SlackChannel} from "@api/slack"
import {useSlackContext} from "../../context/DataProvider"
import Paper from "@mui/material/Paper"
import {useAlert} from "@context/AlertContext"
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import AppTooltip from "@components/AppTooltip"
import {MemberRow} from "./components/MemberRow"
import {Feature, useFeatureSlackPage} from "../../hooks/useFeatureSlackPage"
import PersonSelectorDialog from "@components/dialogs/PersonSelector"

type TProps = {
    channel: SlackChannel
    refetch: () => void
}

export const SlackChannelRow: FC<TProps> = (props) => {
    const {channel, refetch} = props

    const [loading, setLoading] = useState<boolean>(false)
    const [userSelectorOpen, setUserSelectorOpen] = useState<boolean>(false)

    const {ftCheck} = useFeatureSlackPage()
    const {} = useSlackContext()
    const [members, setMembers] = useState<string[]>([])
    const {showAlert} = useAlert()

    const allowManageMembers = ftCheck(Feature.MANAGE_MEMBERS)
    const allowChannelCreation = ftCheck(Feature.CREATE_CHANNEL)

    const updateMembers = useCallback(() => {
        if (channel.is_archived) {
            setMembers([])
            return Promise.resolve()
        }
        setLoading(true)
        return SlackApi
            .listChannelMembers(channel.id)
            .then(setMembers)
            .finally(() => setLoading(false))
    }, [setLoading, setMembers, channel])

    useEffect(() => {
        updateMembers()
            .catch((err) => showAlert(`Failed to load members - ${err.message}`, "error"))
    }, [showAlert, updateMembers])

    const handleAddUsers = (userIds: string[]) => {
        userIds.forEach(userId => {
            SlackApi.addInternalUserToChannel(channel.id, userId)
                .then(() => updateMembers())
                .catch((err) => showAlert(`Failed to add user - ${err.message}`, "error"))
        })
        updateMembers().then()
    }

    const userDialog = (
        // TODO update person selector to return emails instead of user ids?
        userSelectorOpen
            ? <PersonSelectorDialog open={userSelectorOpen}
                                    multiple={true}
                                    onClose={() => setUserSelectorOpen(false)}
                                    onSelect={
                                        (userId) => {
                                            handleAddUsers(userId)
                                            setUserSelectorOpen(false)
                                        }
                                    }/>
            : null
    )

    const userList = (
        members
            .map((email) => (
                <MemberRow key={email}
                           onChange={updateMembers}
                           channelId={channel.id}
                           email={email}
                />
            ))
    )

    const handleArchive = useCallback(() => {
        SlackApi.archiveChannel(channel.id)
            .then(() => refetch())
            .catch(error => showAlert(error.message, "error"))
    }, [refetch, showAlert])

    const handleCreate = useCallback(() => {
        SlackApi.createChannel(channel.name, true)
            .then(() => refetch())
            .catch(error => showAlert(error.message, "error"))
    }, [refetch, showAlert])

    const handleToggle = () => {
        if (channel.is_archived) {
            handleCreate()
        } else {
            handleArchive()
        }
    }
    return (
        <>
            {userDialog}
            <Box sx={{borderTop: "1px solid #555", borderBottom: "1px solid #555"}}>
                <Paper sx={{p: 2, borderBottom: "1px solid #555"}}>
                    <Grid container alignItems={"center"}>
                        <Grid item xs>
                            <Typography variant="body1"
                                        fontSize={18}
                                        color={"text.primary"}>
                                {channel.name}
                            </Typography>
                        </Grid>
                        <Stack direction={"row"} gap={4} alignItems={"center"}>
                            <Stack direction={"row"} gap={1}>
                                {
                                    allowManageMembers && !channel.is_archived
                                        ? (
                                            <AppTooltip title={"Add User"} placement={"top"}>
                                                <IconButton size="medium"
                                                            onClick={() => setUserSelectorOpen(true)}>
                                                    <GroupAddIcon fontSize="medium"/>
                                                </IconButton>
                                            </AppTooltip>
                                        )
                                        : null
                                }
                                {
                                    allowChannelCreation
                                        ? (
                                            <>
                                                <AppTooltip title={"Archive Channel"} placement={"top"}>
                                                    <FormControlLabel
                                                        label={""}
                                                        control={
                                                            <Switch
                                                                checked={!channel.is_archived}
                                                                onChange={() => handleToggle()}
                                                                color="primary"
                                                            />
                                                        }
                                                    />
                                                </AppTooltip>
                                            </>
                                        )
                                        : null
                                }
                            </Stack>
                        </Stack>
                    </Grid>
                </Paper>
                <Box sx={{margin: 1}}>
                    {loading ? <CircularProgress/> : userList}
                </Box>
            </Box>
        </>
    )
}