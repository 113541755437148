import MainLayout from "./components/MainLayout"
import {Outlet} from "react-router-dom"

const PageLayout = () => {
    return (
        <main className="App">
            <MainLayout>
                <Outlet/>
            </MainLayout>
        </main>
    )
}

export default PageLayout