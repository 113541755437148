import {gql} from "@apollo/client"

export type AuthUserResults = {
    authUser: FullAuthUser[]
}

export type SpeedtestUser = {
    id: string
    timestamp: string
    ip: string
    ispinfo: string
    // extra: string
    // ua: string
    // lang: string
    dl: string
    ul: string
    ping: string
    jitter: string
    // log: string
    siteId: string
}

export type FullAuthUser = {
    id: number
    name: string
    email: string
    expires: string
    speedtestUsers: SpeedtestUser[]
}

export const UserQuery = gql`
  query ($userId: Int) {
    authUser(userId: $userId) {
      id
      name
      email
      expires
      speedtestUsers {
        id
        timestamp
        ip
        ispinfo
        dl
        ul
        ping
        jitter
        siteId
      }
    }
  }
`
