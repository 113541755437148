import {SpeedTestApi} from "@api/speedtest"

export const sendEmail = async (
    site: { name: string, link: string },
    user: { name: string, email: string },
) => {
    const text = getEmailText(site, user)
    return SpeedTestApi.sendEmail(user.email, text, site.name)
}

export const getEmailText = (
    site: { name: string, link: string },
    user: { name: string, email: string },
) => {
    return `<p>Hello ${user.name},</p>
            <p>
                Thank you for working with Psyop. To provide the best possible remote user experience, we request that
                you click on the following link and complete a Speed Test for:
            </p>
            <p>
                <a href="${site.link}run?email=${encodeURIComponent(user.email)}">${site.name}</a>
            </p>
            <p>
                This speed test is required for your continued work at Psyop. Please complete this action as soon as
                possible the link will expire in 7 days. There is no need to screenshot the results.
            </p>
            <p>
                If you have any questions or concerns, please reply to this email or contact Speed Test Support:<br />
                <a href="mailto:speedtest-support@psyop.tv">speedtest-support@psyop.tv</a>
            </p>
            <p>
                Sincerely,<br />
                Psyop
            </p>`.trim()
}
