import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import DnsIcon from "@mui/icons-material/Dns"
import HomeIcon from "@mui/icons-material/Home"
import MenuIcon from "@mui/icons-material/Menu"
import PeopleIcon from "@mui/icons-material/People"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import {useTheme} from "@mui/material/styles"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import {FC, ReactElement, useEffect, useState} from "react"
import {useNavigate} from "react-router"
import ProfileButton from "./components/AccountMenu"
import {AppBar, Drawer, DrawerHeader} from "./utils/styles"
import {HEADER_HEIGHT} from "@utils/constants"
import PageLink from "./components/PageLink"
import {PageRoles} from "@utils/roles"
import useAuth from "@hooks/useAuth"
import TheatersIcon from "@mui/icons-material/Theaters"
import FolderIcon from "@mui/icons-material/Folder"
import AgricultureIcon from '@mui/icons-material/Agriculture'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import SpeedIcon from '@mui/icons-material/Speed'
import {Stack} from "@mui/material"

const useConfig = () => {
    const [config, setConfig] = useState<{ version?: string }>({})

    useEffect(() => {
        const fetchConfig = async () => {
            const response = await fetch("/config.json")
            const data = await response.json()
            setConfig(data)
        }
        // noinspection JSIgnoredPromiseFromCall
        fetchConfig()
    }, [])

    return config
}

const MainLayout: FC<{
    children: ReactElement
}> = (props) => {
    const {user} = useAuth()
    const theme = useTheme()
    const [open, setOpen] = useState(false)
    const config = useConfig()
    const navigate = useNavigate()

    const handleDrawerOpen = () => {
        setOpen(true)
    }

    const handleDrawerClose = () => {
        setOpen(false)
    }

    const appBar = (
        <AppBar position="fixed" open={open}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{
                        marginRight: 5,
                        ...(open && {display: "none"}),
                    }}
                >
                    <MenuIcon/>
                </IconButton>

                <Box sx={{flexGrow: 1}}></Box>

                <ProfileButton/>
            </Toolbar>
        </AppBar>
    )

    const drawer = (
        <Drawer variant="permanent" open={open}>
            <DrawerHeader>
                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === "rtl" ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                </IconButton>
            </DrawerHeader>
            <Divider/>
            <List>
                <ListItem key={"home"} disablePadding sx={{display: "block"}}>
                    <ListItemButton
                        sx={{
                            minHeight: HEADER_HEIGHT,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                        }}
                        onClick={() => navigate("/")}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : "auto",
                                justifyContent: "center",
                            }}
                        >
                            <HomeIcon/>
                        </ListItemIcon>
                        <ListItemText primary={"Home"} sx={{opacity: open ? 1 : 0}}/>
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider/>
            <List>
                <PageLink open={open} route="/people" title="People" icon={<PeopleIcon/>}/>
                {user?.role && PageRoles.Workstations.includes(user.role) && (
                    <PageLink open={open} route="/workstations" title="Workstations" icon={<DnsIcon/>}/>
                )}
                {user?.role && PageRoles.Projects.includes(user.role) && (
                    <PageLink open={open} route="/projects" title="Projects" icon={<TheatersIcon/>}/>
                )}
                {user?.role && PageRoles.Files.includes(user.role) && (
                    <PageLink open={open} route="/files" title="Files" icon={<FolderIcon/>}/>
                )}
                {user?.role && PageRoles.RenderFarm.includes(user.role) && (
                    <PageLink open={open} route="/render" title="Render Farm" icon={<AgricultureIcon/>}/>
                )}
                {user?.role && PageRoles.SpeedTest.includes(user.role) && (
                    <PageLink open={open} route="/speedtest" title="Speed Test" icon={<SpeedIcon/>}/>
                )}
                {user?.role && PageRoles.ProjectLeads.includes(user.role) && (
                    <PageLink open={open} route="/leads" title="Project Leads" icon={<AccountBalanceIcon/>}/>
                )}
                {/*<PageLink open={open} route="/licenses" title="Licenses" icon={<LockOpenIcon/>}/>*/}
                {/*<PageLink open={open} route="/admin" title="Admin" icon={<AdminPanelSettingsIcon/>}/>*/}
            </List>
            <Stack width={"100%"}
                   height={"100%"}
                   direction={"row"}
                   justifyContent={"center"}
                   alignItems={"end"}
            >
                <Stack width={"100%"}
                       alignItems={"center"}
                       gap={1}
                       mb={1}
                >
                    <img src={`/images/logo.png`}
                         alt="Logo"
                         style={{
                             paddingTop: "6px",
                             width: "32px",
                             height: "auto",
                         }}/>
                    <Typography variant={"body2"} fontWeight={"bold"}>
                        {`v${config?.version}`}
                    </Typography>
                </Stack>
            </Stack>
        </Drawer>
    )

    return (
        <Box sx={{display: "flex"}}>
            {appBar}
            {drawer}
            <div style={{
                flexGrow: 1,
                padding: 0,
            }}>
                <DrawerHeader/>
                {props.children}
            </div>
        </Box>
    )
}

export default MainLayout