import {Role} from "@utils/roles"
import {useBaseFeature} from "@hooks/useBaseFeature"

export enum Feature {
    MANAGE_ASSIGNMENTS = "manage_assignments",
    MANAGE_SHARES = "manage_shares",
    CREATE_USER = "create_user",
}

const FEATURES = {
    [Feature.CREATE_USER]: new Set([
        Role.Admin,
        Role.Executive,
        Role.Producer,
        Role.Dmg,
    ]),
    [Feature.MANAGE_ASSIGNMENTS]: new Set([
        Role.Admin,
        Role.Executive,
        Role.Producer,
        Role.Dmg,
    ]),
    [Feature.MANAGE_SHARES]: new Set([
        Role.Admin,
        Role.Executive,
        Role.Producer,
        Role.Dmg,
    ]),
}

export const useFeatureAsperaPage = () => {
    return useBaseFeature(FEATURES)
}