import {FC, PropsWithChildren} from "react"
import {styled} from "@mui/system"
import {Box} from "@mui/material"

type TProps = {} & PropsWithChildren<{}>

const Content = styled(Box)({
    paddingLeft: '1rem',
    paddingRight: '1rem',
    height: 'calc(100vh - 64px)', // Required for scroll
    overflowY: 'auto',
    flex: '1 1 auto',
})

const PageContent: FC<TProps> = (props) => {
    const {children} = props

    return (
        <Content>
            {children}
        </Content>
    )
}

export default PageContent