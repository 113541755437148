import {runMethod} from "@api/base"

export type Drive = {
    name: string
    url: string
}

const getDrive = async (name: string): Promise<Drive | null> => {
    const response = await runMethod<{ data: Drive }>({
        method: "GET",
        route: `/api/v1/google/drive/${name}`,
        ignoreStatuses: [404],
    })
    return response?.data || null
}

const createDrive = async (name: string): Promise<Drive> => {
    const response = await runMethod<{ data: Drive }>({
        method: "POST",
        route: "/api/v1/google/drive",
        body: {name},
    })
    return response.data
}

const joinDrive = async (name: string): Promise<void> => {
    await runMethod({
        method: "PUT",
        route: "/api/v1/google/drive/join",
        body: {name},
    })
}

const deleteDrive = async (name: string): Promise<void> => {
    await runMethod({
        method: "DELETE",
        route: `/api/v1/google/drive/${name}`,
    })
}

export class GoogleApi {
    static getDrive = getDrive
    static createDrive = createDrive
    static joinDrive = joinDrive
    static deleteDrive = deleteDrive
}