import {getSessionAuth} from "@utils/session"
import axios, {AxiosRequestConfig, ResponseType} from "axios"

interface RunMethodConfig {
    method: string
    route: string
    body?: any
    headers?: object
    timeout?: number
    responseType?: ResponseType
    ignoreStatuses?: number[]
    signal?: AbortSignal
}

export const eventSource = (url: string): EventSource => {
    const auth = getSessionAuth()
    if (!auth) {
        throw new Error("User is not authenticated")
    }
    return new EventSource(url + "?access_token=" + auth.accessToken)
}

export const runMethod = async <T>(config: RunMethodConfig): Promise<T> => {
    const {
        method,
        route,
        body,
        headers = {},
        timeout = 60000,
        responseType = 'json',
        ignoreStatuses = [],
        signal,
    } = config
    const auth = getSessionAuth()
    if (!auth) {
        throw new Error("User is not authenticated")
    }

    const axiosConfig: AxiosRequestConfig = {
        method: method as any,
        url: route,
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `Bearer ${auth.accessToken}`,
            ...headers,
        },
        data: body,
        timeout: timeout,
        responseType: responseType,
        validateStatus: (status) => {
            return (status >= 200 && status < 300) || ignoreStatuses.includes(status)
        },
        signal: signal,
    }

    try {
        const response = await axios(axiosConfig)
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data)
        } else {
            throw error
        }
    }
}
