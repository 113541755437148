import {useQuery} from "@apollo/client"
import {Resources, useApolloClient} from "@hooks/useApolloClient"
import {useEffect, useMemo} from "react"
import type {DocumentNode} from "graphql/index"
import {useAlert} from "@context/AlertContext"

export type QueryDetails<TQResult, TDResult> = {
    query: DocumentNode,
    resourceKey: Resources,
    resultSelector: (data: TQResult | undefined) => TDResult | undefined
}

export const useGenericQuery = <TQueryResult, TDataResult>(
    queryDetails: QueryDetails<TQueryResult, TDataResult>,
    variables?: any,
) => {
    const {query, resourceKey, resultSelector} = queryDetails
    const client = useApolloClient(resourceKey)
    const {data, loading, error, refetch, startPolling, stopPolling} = useQuery<TQueryResult>(query, {
        client: client,
        errorPolicy: "all",
        notifyOnNetworkStatusChange: true,
        variables: variables,
    })
    const result = useMemo<TDataResult | undefined>(() => resultSelector(data), [resultSelector, data])
    const {showAlert} = useAlert()

    useEffect(() => error && showAlert(error.message, "error"), [error, showAlert])

    return {result, loading, error, refetch, startPolling, stopPolling}
}