import {FC, useState} from "react"
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material"
import {AsperaShareDropdown} from "@components/aspera/AsperaShareDropdown"

type AsperaSelectorDialogProps = {
    open: boolean
    onClose: () => void
    onSelect: (shareIds: number[]) => void
}

const AsperaShareSelector: FC<AsperaSelectorDialogProps> = (props) => {
    const {open, onClose, onSelect} = props

    const [selectedShares, setSelectedShares] = useState<number[] | null>(null)
    const [loading, setLoading] = useState<boolean>(false)

    const handleShareSelect = (shareIds: number[]) => {
        setSelectedShares(shareIds)
    }

    const handleConfirm = () => {
        if (selectedShares) {
            setLoading(true)
            onSelect(selectedShares)
            setLoading(false)
            onClose()
        }
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Select a Share</DialogTitle>
            <DialogContent>
                <AsperaShareDropdown
                    multiSelectEnabled={true}
                    value={selectedShares || []}
                    onValueChange={handleShareSelect}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleConfirm} color="primary" disabled={!selectedShares || loading}>
                    {loading ? <CircularProgress size={24}/> : "Confirm"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AsperaShareSelector