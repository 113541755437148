import {jwtDecode, JwtPayload} from "jwt-decode"
import {COOKIE_SESSION_ID} from "./constants"
import {AuthState} from "@type/auth"

const STORAGE_TYPE = localStorage

export const setSessionId = (id: string) => {
    STORAGE_TYPE.setItem(COOKIE_SESSION_ID, id)
}

export const getSessionId = (): string => {
    return STORAGE_TYPE.getItem(COOKIE_SESSION_ID) || ""
}

export const clearSessionId = () => {
    STORAGE_TYPE.removeItem(COOKIE_SESSION_ID)
}

export const getSessionAuth = (): AuthState | null => {
    const savedAuth = STORAGE_TYPE.getItem("auth")
    return savedAuth ? JSON.parse(savedAuth) : null
}

export const setSessionAuth = (auth: AuthState | null) => {
    if (auth) {
        STORAGE_TYPE.setItem("auth", JSON.stringify(auth))
    } else {
        STORAGE_TYPE.removeItem("auth")
    }
}

export const isExpiringSoon = (seconds: number = 60 * 60 * 6): boolean => {
    const auth = getSessionAuth()
    if (!auth) {
        return false
    }

    const decoded: JwtPayload = jwtDecode(auth.accessToken)
    if (!decoded.exp) {
        return false
    }

    const now = Date.now() / 1000
    const diff = decoded.exp - now
    return 0 < diff && diff < seconds
}

const hasToken = () => {
    return getSessionAuth() !== null
}

export const isTokenExpired = () => {
    const auth = getSessionAuth()
    if (!auth) {
        return false
    }

    const decoded: JwtPayload = jwtDecode(auth.accessToken)
    if (!decoded.exp) {
        return true
    }

    const now = Date.now() / 1000

    return decoded.exp < now
}

export const isTokenValid = () => {
    return hasToken() && !isTokenExpired()
}
