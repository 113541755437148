import React, {FC, useEffect, useState} from "react"
import {Box, IconButton} from "@mui/material"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import {styled} from "@mui/system"

const WIDTH = 256

type TProps = {
    height?: string | number,
    width?: number,
    direction?: "left" | "right"
} & React.PropsWithChildren<{}>

const Sidebar = styled(Box)(({height, width, expanded, direction}: {
    height: string | number,
    width: number,
    expanded: string,
    direction: string
}) => ({
    height: height,
    overflowY: "auto",
    overflowX: "hidden",
    flex: `0 0 ${expanded === "true" ? width : 0}px`,
    width: expanded === "true" ? width : 0,
    flexShrink: 0,
    position: "relative",
    padding: expanded === "true" ? "0.5rem" : 0,
    boxSizing: "border-box",
    order: direction === "right" ? 1 : 0,
}))

const ToggleButton = styled(IconButton)(({direction}: { direction: string }) => ({
    zIndex: 1,
    position: "absolute",
    top: 1,
    [direction === "right" ? "left" : "right"]: direction === "right" ? -8 : -32,
}))

export const CollapsibleSidebar: FC<TProps> = (props) => {
    const {children, height = "100%", width = WIDTH, direction = "left"} = {...props}

    const [userChanged, setUserChanged] = useState<boolean>(false)
    const [isExpanded, setIsExpanded] = useState<boolean>(true)

    useEffect(() => {
        const handleResize = () => {
            if (userChanged) return
            setIsExpanded(window.innerWidth > 800)
        }
        window.addEventListener("resize", handleResize)
        handleResize()
        return () => window.removeEventListener("resize", handleResize)
    }, [userChanged])

    return (
        <Box sx={{
            position: "relative",
            display: "flex",
            flexDirection: direction === "right" ? "row-reverse" : "row",
        }}>
            <Sidebar
                height={height}
                width={width}
                hidden={!isExpanded}
                expanded={isExpanded ? "true" : "false"}
                direction={direction}
            >
                {children}
            </Sidebar>
            <ToggleButton
                onClick={() => {
                    setIsExpanded(!isExpanded)
                    setUserChanged(true)
                }}
                direction={direction}
            >
                {
                    isExpanded
                        ? direction === "right" ? <ArrowForwardIosIcon/> : <ArrowBackIosIcon/>
                        : direction === "right" ? <ArrowBackIosIcon/> : <ArrowForwardIosIcon/>
                }
            </ToggleButton>
        </Box>
    )
}