import {runMethod} from "@api/base"
import {Dayjs} from "dayjs"

export type Project = {
    id: string
    name: string
}

const buildProjectUrl = (projectId: string): string => {
    return `https://syncsketch.com/pro/project/${projectId}/`
}

const getProject = async (name: string): Promise<Project | null> => {
    const response = await runMethod<{ data: Project }>({
        method: "GET",
        route: `/api/v1/syncsketch/project/by_name/${name}`,
        ignoreStatuses: [404],
    })
    return response.data || null
}

const createProject = async (name: string): Promise<Project> => {
    const response = await runMethod<{ data: Project }>({
        method: "POST",
        route: "/api/v1/syncsketch/project/create",
        body: {project_name: name},
    })
    return response.data
}

const archiveProject = async (name: string): Promise<void> => {
    await runMethod({
        method: "DELETE",
        route: `/api/v1/syncsketch/project/archive/${name}`,
    })
}

const assignProject = async (
    projectId: string,
    email: string,
    permission: string,
    note: string,
    expiration: Dayjs,
): Promise<void> => {
    const expireDate = expiration.toISOString().split('T')[0]
    await runMethod({
        method: "POST",
        route: "/api/v1/syncsketch/project/assign",
        body: {
            project_id: projectId,
            email,
            permission,
            note,
            expiration: expireDate,
        },
    })
}

const hasWorkspaceAccess = async (email: string): Promise<boolean> => {
    const response = await runMethod<{ data: boolean }>({
        method: "POST",
        route: "/api/v1/syncsketch/workspace/access",
        body: {email},
    })
    return response.data
}

const hasProjectAccess = async (projectId: string, email: string): Promise<boolean> => {
    const response = await runMethod<{ data: boolean }>({
        method: "POST",
        route: "/api/v1/syncsketch/project/access",
        body: {
            project_id: projectId,
            email,
        },
    })
    return response.data
}

export class SyncSketchApi {
    static buildProjectUrl = buildProjectUrl
    static getProject = getProject
    static createProject = createProject
    static archiveProject = archiveProject
    static assignProject = assignProject
    static hasWorkspaceAccess = hasWorkspaceAccess
    static hasProjectAccess = hasProjectAccess
}
