import {useGenericQuery} from "@hooks/useGenericQueryHook"
import {FullAuthUser, AuthUserResults, UserQuery} from "../query/user.query"

export const useSpeedTestUser = (userId: number) => {
    const {result, loading, error} = useGenericQuery<AuthUserResults, FullAuthUser>({
        query: UserQuery,
        resourceKey: "speedtest",
        resultSelector: data => data?.authUser[0],
    }, {userId: userId})

    return {result, loading, error}
}