import {Role} from "@utils/roles"
import {useBaseFeature} from "@hooks/useBaseFeature"

export enum Feature {
    CREATE_CHANNEL = "create_channel",
    MANAGE_MEMBERS = "manage_members",
}

const FEATURES = {
    [Feature.CREATE_CHANNEL]: new Set([
        Role.Admin,
        Role.Executive,
        Role.Producer,
    ]),
    [Feature.MANAGE_MEMBERS]: new Set([
        Role.Admin,
        Role.Executive,
        Role.Producer,
    ]),
}

export const useFeatureSlackPage = () => {
    return useBaseFeature(FEATURES)
}