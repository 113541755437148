import React, {FC, useCallback, useEffect, useState} from "react"
import {AsperaApi, AsperaShare, AsperaUser} from "@api/aspera"
import {Grid, IconButton, Stack} from "@mui/material"
import {AsperaDataProvider, useAsperaContext} from "./context/DataProvider"
import {AsperaShareRow} from "./components/AsperaShareRow"
import AsperaUserCreateDialog from "components/dialogs/AsperaUserCreator"
import {useAlert} from "@context/AlertContext"
import AsperaShareCreateDialog from "@components/dialogs/AsperaShareCreator"
import {useDataContext} from "../../context/DataProvider"
import PersonAddIcon from "@mui/icons-material/PersonAdd"
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder'
import AppTooltip from "@components/AppTooltip"
import AddLinkIcon from '@mui/icons-material/AddLink'
import AsperaShareSelector from "@components/dialogs/AsperaShareSelector"
import {Feature, useFeatureAsperaPage} from "./hooks/useFeatureAsperaPage"

const ProjectAsperaComponent: FC = () => {
    const {project} = useDataContext()
    const [shares, setShares] = useState<AsperaShare[]>([])
    const [showUserCreate, setShowUserCreate] = useState<boolean>(false)
    const [showShareCreate, setShowShareCreate] = useState<boolean>(false)
    const [showShareAttach, setShowShareAttach] = useState<boolean>(false)
    const {showAlert} = useAlert()
    const {refetchUsers} = useAsperaContext()
    const {ftCheck} = useFeatureAsperaPage()

    const refetchShares = useCallback(() => {
        if (!project) return
        AsperaApi.listShares(project.id)
            .then(setShares)
    }, [project])
    useEffect(() => refetchShares(), [refetchShares])

    const allowManageShares = ftCheck(Feature.MANAGE_SHARES)
    const allowCreateUser = ftCheck(Feature.CREATE_USER)

    const createUserDialog = (
        showUserCreate
            ? <AsperaUserCreateDialog open={showUserCreate}
                                      onClose={() => setShowUserCreate(false)}
                                      onCreate={(user: AsperaUser) => {
                                          refetchUsers()
                                          showAlert(`User ${user.first_name} ${user.last_name} created`, "success")
                                      }}/>
            : null
    )

    const createShareDialog = (
        showShareCreate && project
            ? <AsperaShareCreateDialog project={project}
                                       open={showShareCreate}
                                       onClose={() => setShowShareCreate(false)}
                                       onCreate={(share: AsperaShare) => {
                                           refetchShares()
                                           showAlert(`Share ${share.name} created`, "success")
                                       }}/>
            : null
    )

    const attachShareDialog = (
        showShareAttach && project
            ? <AsperaShareSelector open={showShareAttach}
                                   onClose={() => setShowShareAttach(false)}
                                   onSelect={(shareIds: number[]) => {
                                       AsperaApi.attachShares(project.id, shareIds)
                                           .then(() => refetchShares())
                                           .then(() => showAlert("Share attached", "success"))
                                   }}/>
            : null
    )

    return (
        <>
            {createUserDialog}
            {createShareDialog}
            {attachShareDialog}
            <Grid container
                  gap={4}
                  justifyContent={"flex-start"}
                  alignContent={"flex-start"}>
                <AsperaDataProvider>
                    <Stack gap={4} width={"100%"}>
                        <Stack direction={"row"} gap={1} justifyContent={"flex-end"}>
                            {
                                allowCreateUser
                                    ? (
                                        <AppTooltip title={"Create User"} placement={"bottom"}>
                                            <IconButton size="large"
                                                        onClick={() => setShowUserCreate(true)}>
                                                <PersonAddIcon fontSize="large"/>
                                            </IconButton>
                                        </AppTooltip>
                                    )
                                    : null
                            }
                            {
                                allowManageShares
                                    ? (
                                        <>
                                            <AppTooltip title={"Create Share"} placement={"top"}>
                                                <IconButton size="large"
                                                            onClick={() => setShowShareCreate(true)}>
                                                    <CreateNewFolderIcon fontSize="large"/>
                                                </IconButton>
                                            </AppTooltip>
                                            <AppTooltip title={"Attach Share"} placement={"top"}>
                                                <IconButton size="large"
                                                            onClick={() => setShowShareAttach(true)}>
                                                    <AddLinkIcon fontSize="large"/>
                                                </IconButton>
                                            </AppTooltip>
                                        </>
                                    )
                                    : null
                            }
                        </Stack>
                        {shares
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(share => (
                                <AsperaShareRow key={share.id}
                                                projectId={project?.id}
                                                asperaShare={share}
                                                onShareRemove={() => refetchShares()}
                                />
                            ))}
                    </Stack>
                </AsperaDataProvider>
            </Grid>
        </>
    )
}

const ProjectAsperaPage: FC = () => {
    return (
        <AsperaDataProvider>
            <ProjectAsperaComponent/>
        </AsperaDataProvider>
    )
}

export default ProjectAsperaPage