import React from "react"
import {ScoreLevel} from "../types/ScoreLevel"

type ScoreProps = {
    score: ScoreLevel | null
    value: string | number
}

export const Score: React.FC<ScoreProps> = (props) => {
    const {score, value} = props
    const color = getColor(score)
    return <span style={{color: color}}>{value}</span>
}

const getColor = (score: ScoreLevel | null): string => {
    if (score === null) return "white"
    return {
        "pass": "green",
        "warn": "orange",
        "fail": "red",
    }[score]
}

