import {FC, useCallback, useEffect, useState} from "react"
import {AddCircle, Cancel, OpenInNew} from "@mui/icons-material"
import {useAlert} from "@context/AlertContext"
import {Feature, useFeature} from "./hooks/useFeature"
import BaseFeatureRow from "../BaseFeatureRow"
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import BaseFeatureButton from "../BaseFeatureButton"
import {Stack} from "@mui/material"
import {Project, SyncSketchApi} from "@api/syncsketch"
import useAuth from "@hooks/useAuth"
import dayjs from "dayjs"
import {useConfirmationDialog} from "@hooks/useConfirmationDialog"

type TProps = {
    projectName: string,
}

const SyncSketch: FC<TProps> = ({projectName}) => {
    const [project, setProject] = useState<Project | null>(null)
    const [hasAccess, setHasAccess] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)
    const {showAlert} = useAlert()
    const {ftCheck} = useFeature()
    const {user} = useAuth()
    const {openDialog, ConfirmationDialog} = useConfirmationDialog()

    const allowManage = ftCheck(Feature.MANAGE)

    useEffect(() => {
        let isMounted = true
        setHasAccess(false)
        if (project && user?.accountId) {
            SyncSketchApi.hasProjectAccess(project.id, user.accountId)
                .then((res) => {
                    if (!isMounted) return
                    if (res) return setHasAccess(true)
                    SyncSketchApi.hasWorkspaceAccess(user.accountId)
                        .then(res => {
                            if (isMounted) setHasAccess(res)
                        })
                        .catch(error => {
                            if (isMounted) showAlert(error.message, "error")
                        })
                })
                .catch(error => {
                    if (isMounted) showAlert(error.message, "error")
                })
        }
        return () => {
            isMounted = false
        }
    }, [project, user?.accountId, showAlert])

    useEffect(() => {
        let isMounted = true
        setLoading(true)
        SyncSketchApi.getProject(projectName)
            .then(proj => {
                if (isMounted) setProject(proj)
            })
            .catch(error => {
                if (isMounted) showAlert(error.message, "error")
            })
            .finally(() => {
                if (isMounted) setLoading(false)
            })
        return () => {
            isMounted = false
        }
    }, [projectName, showAlert])

    const handleCreate = useCallback(() => {
        setLoading(true)
        SyncSketchApi.createProject(projectName)
            .then(proj => setProject(proj))
            .catch(error => showAlert(error.message, "error"))
            .finally(() => setLoading(false))
    }, [projectName, showAlert])

    const handleArchive = useCallback(() => {
        setLoading(true)
        SyncSketchApi.archiveProject(projectName)
            .then(() => setProject(null))
            .catch(error => showAlert(error.message, "error"))
            .finally(() => setLoading(false))
    }, [projectName, showAlert])

    const handleCheckout = useCallback(() => {
        if (!project || !user) {
            showAlert("Project or user not found", "error")
            return
        }
        const expiration = dayjs().add(1, "day")
        const expireDate = expiration.toISOString().split('T')[0]

        setLoading(true)
        SyncSketchApi.assignProject(project.id, user.accountId, "member", `Temporary Access for ${project.name}`, expiration)
            .then(() => {
                showAlert(`Account enabled until ${expireDate}, please check your ${user.accountId} email for the invite.`, "success")
            })
            .catch(error => showAlert(error.message, "error"))
            .finally(() => setLoading(false))
    }, [project, user, showAlert])

    let buttons = []
    if (allowManage) {
        buttons.push(
            <BaseFeatureButton
                key={"create"}
                disabled={loading || !!project}
                label={"Create"}
                icon={<AddCircle/>}
                onClick={() => {
                    openDialog({
                        title: "Create Project",
                        message: `Are you sure you want to create project ${projectName}?`,
                        onAccept: handleCreate,
                    })
                }}
            />,
        )
        buttons.push(
            <BaseFeatureButton
                key={"delete"}
                disabled={loading || !project}
                label={"Archive"}
                icon={<Cancel/>}
                onClick={() => {
                    openDialog({
                        title: "Archive Project",
                        message: `Are you sure you want to archive project ${projectName}?`,
                        onAccept: handleArchive,
                    })
                }}
            />,
        )
    }
    if (project) {
        if (hasAccess) {
            buttons.push(
                <BaseFeatureButton
                    key={"open"}
                    disabled={loading}
                    label={"Open"}
                    icon={<OpenInNew/>}
                    onClick={() => window.open(SyncSketchApi.buildProjectUrl(project.id), "_blank")}
                />,
            )
        } else {
            buttons.push(
                <BaseFeatureButton
                    key={"checkout"}
                    disabled={loading || !project || !user}
                    label={"Checkout"}
                    icon={<VpnKeyIcon/>}
                    onClick={() => {
                        openDialog({
                            title: "Checkout Project",
                            message: `Are you sure you want to checkout project ${projectName}?`,
                            onAccept: handleCheckout,
                        })
                    }}
                />,
            )
        }
    }

    return (
        <>
            <ConfirmationDialog/>
            <BaseFeatureRow
                title={"SyncSketch"}
                loading={loading}
                isActive={!!project}
            >
                <Stack direction="row" gap={1}>
                    {buttons}
                </Stack>
            </BaseFeatureRow>
        </>
    )
}

export default SyncSketch