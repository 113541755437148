export type Site = {
    code: number
    name: string
    link: string
}

export const SITES: Site[] = [
    {code: 1, name: "US Cloud (use)", link: "https://aws-use-speed.psyop.tv/"},
    {code: 5, name: "EU Cloud (ew1)", link: "https://speed-ew1.psyop.tv/"},
]
