import React, {FC} from "react"
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from "@mui/material"
import {getDistance, IspInfo} from "../types/IspInfo"
import {SpeedtestUser} from "../query/user.query"

type TProps = {
    open: boolean
    onClose: () => void
    testResults: SpeedtestUser
    ispDetail: IspInfo
}

const AddressInfoDialog: FC<TProps> = (props) => {
    const {open, onClose, testResults, ispDetail} = props

    return (
        <Dialog open={open}
                onClose={onClose}
                fullWidth
                color="primary">
            <DialogTitle>
                Test Details
            </DialogTitle>
            <DialogContent>
                <TableContainer>
                    <Table size="small">
                        <TableBody>
                            <TableRow hover>
                                <TableCell>ISP</TableCell>
                                <TableCell>{ispDetail.rawIspInfo.org}</TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>Hostname</TableCell>
                                <TableCell>{ispDetail.rawIspInfo.hostname}</TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>Distance</TableCell>
                                <TableCell>{getDistance(ispDetail)} mi</TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>Jitter</TableCell>
                                <TableCell>{testResults.jitter} ms</TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>Lat/Long</TableCell>
                                <TableCell>
                                    <Link target="_blank" color="secondary"
                                          href={`https://www.google.com/maps/search/?api=1&query=${ispDetail.rawIspInfo.loc}`}>
                                        {ispDetail.rawIspInfo.loc}
                                    </Link>
                                </TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>Location</TableCell>
                                <TableCell>
                                    {ispDetail.rawIspInfo.city}, {ispDetail.rawIspInfo.region}
                                    <br/>
                                    {ispDetail.rawIspInfo.postal}
                                    <br/>
                                    {ispDetail.rawIspInfo.country}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()} color="secondary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddressInfoDialog