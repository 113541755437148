import {useAlert} from "@context/AlertContext"
import {useEffect, useState} from "react"
import {AsperaApi, AsperaNode} from "@api/aspera"

export const useAsperaNodeData = () => {
    const [loading, setLoading] = useState<boolean>(true)
    const [nodes, setNodes] = useState<AsperaNode[]>([])
    const {showAlert} = useAlert()

    useEffect(() => {
        setLoading(true)
        AsperaApi.listNodes()
            .then((res) => {
                setNodes(res.sort((a, b) => a.name.localeCompare(b.name)))
            })
            .catch((error) => showAlert(error.message, "error"))
            .finally(() => setLoading(false))
    }, [showAlert])

    return {loading, nodes}
}