import {FC, PropsWithChildren} from "react"
import {CircularProgress, IconButton, Stack} from "@mui/material"
import {Cancel, CheckCircle} from "@mui/icons-material"

type TProps = {
    title: string
    loading: boolean,
    isActive: boolean,
} & PropsWithChildren

const BaseFeatureRow: FC<TProps> = (props) => {
    const {children, title, loading, isActive} = props

    return (
        <Stack direction={"row"}
               style={{alignItems: 'center'}}
               borderBottom={"1px solid white"}
               margin={1}
               padding={1}
        >
            {
                loading ? <CircularProgress size={"24px"}/>
                    : (
                        <IconButton size={"small"}>
                            {isActive ? <CheckCircle color="primary"/> : <Cancel color="error"/>}
                        </IconButton>
                    )
            }
            <span style={{marginLeft: '8px', marginRight: 'auto'}}>{title}</span>
            {children}
        </Stack>
    )
}

export default BaseFeatureRow