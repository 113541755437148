import React, {createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useState} from "react"
import {AsperaApi, AsperaUser} from "@api/aspera"

type TDataContext = {
    refetchUsers: () => void
    getUser: (userId: number) => AsperaUser
}

export const AsperaContext = createContext<TDataContext>({
    refetchUsers: () => {
    },
    getUser: () => {
        return {} as AsperaUser
    }
})

type TPropsDataProvider = {} & PropsWithChildren

export const AsperaDataProvider: FC<TPropsDataProvider> = (props) => {
    const {children} = props

    const [users, setUsers] = useState<AsperaUser[]>([])

    const refetchUsers = useCallback(() => {
        AsperaApi.listLocalUsers().then(setUsers)
    }, [])

    const getUser = useCallback((userId: number) => {
        const user = users.find(user => user.user_id === userId)
        return user || {} as AsperaUser
    }, [users])

    useEffect(() => {
        refetchUsers()
    }, [refetchUsers])

    return (
        <AsperaContext.Provider value={{
            refetchUsers,
            getUser,
        }}>
            {children}
        </AsperaContext.Provider>
    )
}

export const useAsperaContext = () => {
    return useContext(AsperaContext)
}
