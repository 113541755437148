import {useCallback, useState} from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material'

type ConfirmationDialogOptions = {
    title: string
    message: string
    onAccept: () => void
    onCancel?: () => void
}

export const useConfirmationDialog = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [options, setOptions] = useState<ConfirmationDialogOptions | null>(null)

    const openDialog = useCallback((newOptions: ConfirmationDialogOptions) => {
        setOptions(newOptions)
        setIsOpen(true)
    }, [])

    const closeDialog = useCallback(() => {
        setIsOpen(false)
        setOptions(null)
    }, [])

    const handleAccept = useCallback(() => {
        options?.onAccept()
        closeDialog()
    }, [options, closeDialog])

    const handleCancel = useCallback(() => {
        if (options?.onCancel) {
            options.onCancel()
        }
        closeDialog()
    }, [options, closeDialog])

    const ConfirmationDialog = () => (
        <Dialog open={isOpen} onClose={handleCancel}>
            <DialogTitle>{options?.title}</DialogTitle>
            <DialogContent>{options?.message}</DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={handleAccept} color="primary">
                    Accept
                </Button>
            </DialogActions>
        </Dialog>
    )

    return {openDialog, ConfirmationDialog}
}