import {ApolloClient, createHttpLink, InMemoryCache} from "@apollo/client"
import {getSessionAuth} from "@utils/session"
import {useMemo} from "react"

export type Resources = "resources" | "config" | "farm" | "speedtest" | "filesystem" | "finance"

export const useApolloClient = (name: Resources) => {
    return useMemo(() => {
        const auth = getSessionAuth()
        const headers = {"Authorization": `Bearer ${auth?.accessToken}`}

        const httpLink = createHttpLink({
            uri: `/api/v1/graphql/${name}`,
            headers: headers,
        })

        return new ApolloClient({
            link: httpLink,
            cache: new InMemoryCache({}),
            defaultOptions: {
                watchQuery: {
                    fetchPolicy: "no-cache",
                    errorPolicy: "ignore",
                },
                query: {
                    fetchPolicy: "no-cache",
                    errorPolicy: "all",
                },
            },
        })
    }, [name])
}
