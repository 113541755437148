import {useApolloClient} from "@hooks/useApolloClient"
import {useQuery} from "@apollo/client"
import {PersonResults, Query} from "../query/person.query"
import {useAlert} from "@context/AlertContext"
import {useEffect} from "react"

export const usePersonData = () => {
    const client = useApolloClient("resources")
    const {data, loading, error} = useQuery<PersonResults>(
        Query,
        {
            client: client,
            errorPolicy: "all",
            notifyOnNetworkStatusChange: true,
        },
    )

    const {showAlert} = useAlert()
    useEffect(() => error && showAlert(error.message, "error"), [error, showAlert])

    return {data, loading, error}
}