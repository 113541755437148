import React, {useState} from "react"
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField} from "@mui/material"
import {Site, SITES} from "../constants"
import {useAlert} from "@context/AlertContext"
import {AuthUser, SpeedTestApi} from "@api/speedtest"
import {sendEmail} from "../utils"

interface UserCreationDialogProps {
    open: boolean
    onClose: () => void
    onUserCreated: (user: AuthUser) => void
}

const NewUserDialog: React.FC<UserCreationDialogProps> = ({open, onClose, onUserCreated}) => {
    const [newName, setNewName] = useState("")
    const [newEmail, setNewEmail] = useState("")
    const [site, setSite] = useState<Site | null>(null)
    const {showAlert} = useAlert()

    const checkEmail = (str: string): boolean => {
        return /^[a-zA-Z0-9.!#$%&"*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/.test(str)
    }

    const addNewUser = async (): Promise<AuthUser> => {
        const user = await SpeedTestApi.createUser(newName, newEmail)
        setNewName("")
        setNewEmail("")
        onClose()
        onUserCreated(user)
        return user
    }

    const handleInvite = (): void => {
        addNewUser()
            .then((user) => {
                sendEmail(site!, user)
                    .then(() => {
                        showAlert("Email sent.", "success")
                    })
                return user
            })
    }

    const handleChange = (site_id: number): void => {
        const site = SITES.find(s => s.code === site_id)
        site && setSite(site)
    }

    return (
        <Dialog color="primary" open={open}>
            <DialogTitle>Invite New User</DialogTitle>
            <DialogContent sx={{p: 2}}>
                <TextField
                    label="Name"
                    fullWidth={true}
                    sx={{mt: 2}}
                    onChange={(event) => {
                        setNewName(event.target.value)
                    }}
                />
                <TextField
                    label="Email"
                    fullWidth={true}
                    sx={{mt: 2}}
                    onChange={(event) => {
                        setNewEmail(event.target.value)
                    }}
                />
                <TextField
                    label="Site"
                    fullWidth={true}
                    sx={{mt: 2}}
                    select
                    value={site ? site.code : ""}
                    onChange={(event) => {
                        handleChange(Number(event.target.value))
                    }}
                >
                    {SITES.map((site) => (
                        <MenuItem key={site.code} value={site.code}>
                            {site.name}
                        </MenuItem>
                    ))}
                </TextField>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={(): void => {
                    setNewName("")
                    setNewEmail("")
                    onClose()
                }}>
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    disabled={
                        Boolean(newName === "") ||
                        Boolean(newEmail === "") ||
                        Boolean(site === null) ||
                        !checkEmail(newEmail)
                    }
                    onClick={handleInvite}
                >
                    Invite New User
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default NewUserDialog