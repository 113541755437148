import {Button, Stack, Typography} from "@mui/material"
import {FC} from "react"
import {useNavigate} from "react-router-dom"

const NotFoundScreen: FC = () => {
    const navigate = useNavigate()

    return (
        <Stack
            spacing={2}
            alignItems="center"
            sx={{
                paddingTop: "20vh",
                minHeight: "100vh",
            }}
        >
            <Typography
                variant="h4"
                component="h1"
                align="center"
            >
                Secret page unlocked!
            </Typography>
            <Typography
                align="center"
                color="error"
            >
                Just kidding, you shouldn't be here.
            </Typography>
            <Button variant="contained" onClick={() => navigate("/")}>
                Go Home
            </Button>
        </Stack>
    )
}

export default NotFoundScreen