import React, {FC} from 'react'
import Tooltip, {TooltipProps} from '@mui/material/Tooltip'
import {Typography} from "@mui/material"

interface TProps extends TooltipProps {
}

const AppTooltip: FC<TProps> = (props) => {
    const {children, title, placement = "right", ...other} = props

    const titleComponent = typeof title === "string"
        ? <Typography variant={"body1"}>{title}</Typography>
        : title

    return (
        <Tooltip
            title={titleComponent}
            arrow
            placement={placement}
            enterDelay={1000}
            leaveDelay={100}
            {...other}
        >
            {children}
        </Tooltip>
    )
}

export default AppTooltip