import {FC} from "react"
import {CircularProgress, Grid, Stack, Typography} from "@mui/material"
import GoogleDriveRow from "./components/GoogleDriveRow"
import {Project, useDataContext} from "../../context/DataProvider"
import SyncSketchRow from "./components/SyncSketchRow"

const ProjectFeaturePageComponent: FC<{ project: Project }> = (props) => {
    const {project} = props

    return (
        <>
            <GoogleDriveRow driveName={project.name}/>
            <SyncSketchRow projectName={project.name}/>
        </>
    )
}

type TProps = {}

const ProjectFeaturePage: FC<TProps> = () => {
    const {project} = useDataContext()

    if (!project) {
        return (
            <Grid container
                  gap={2}
                  padding={2}
                  justifyContent="center"
            >
                <CircularProgress/>
            </Grid>
        )
    }

    return (
        <Stack width={"100%"} spacing={2}>
            <Typography variant="h4">Features</Typography>
            <ProjectFeaturePageComponent project={project}/>
        </Stack>
    )
}

export default ProjectFeaturePage