import {runMethod} from "@api/base"

export interface SlackChannel {
    id: string
    name: string
    is_private: boolean
    is_archived: boolean
}

const createChannel = async (name: string, isPrivate: boolean = false): Promise<SlackChannel> => {
    const body = {private: isPrivate}

    const response = await runMethod<{ data: SlackChannel }>({
        method: "POST",
        route: `/api/v1/slack/channel/${name}`,
        body: body,
    })
    return response.data
}

const archiveChannel = async (channelId: string): Promise<void> => {
    await runMethod({
        method: "DELETE",
        route: `/api/v1/slack/channel/${channelId}`,
    })
}

const listChannels = async (pattern: string): Promise<SlackChannel[]> => {
    const response = await runMethod<{ data: SlackChannel[] }>({
        method: "GET",
        route: `/api/v1/slack/channels/${pattern}`,
    })
    return response.data
}

const listChannelMembers = async (channelId: string): Promise<string[]> => {
    const response = await runMethod<{ data: string[] }>({
        method: "GET",
        route: `/api/v1/slack/channel/${channelId}/members`,
    })
    return response.data
}

const addInternalUserToChannel = async (channelId: string, userId: string): Promise<void> => {
    await runMethod({
        method: "PUT",
        route: `/api/v1/slack/channel/${channelId}/internal_user/${userId}`,
    })
}

const removeUserFromChannel = async (channelId: string, email: string): Promise<void> => {
    await runMethod({
        method: "DELETE",
        route: `/api/v1/slack/channel/${channelId}/user/${email}`,
    })
}

export class SlackApi {
    static createChannel = createChannel
    static archiveChannel = archiveChannel
    static addInternalUserToChannel = addInternalUserToChannel
    static removeUserFromChannel = removeUserFromChannel
    static listChannels = listChannels
    static listChannelMembers = listChannelMembers
}