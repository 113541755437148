import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material"
import {FC} from "react"

type TProps = {
    title: string,
    message: string,
    open: boolean,
    onCancel: () => void,
    onAccept: () => void,
}

const ConfirmationDialog: FC<TProps> = (props) => {
    const {
        title,
        message,
        open,
        onCancel,
        onAccept,
    } = props

    return (
        <Dialog open={open}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus
                        onClick={onCancel}>
                    Cancel
                </Button>
                <Button autoFocus
                        onClick={onAccept}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmationDialog