import React, {createContext, ReactNode, useCallback, useContext, useState} from 'react'
import {Alert, AlertColor, Snackbar} from '@mui/material'

interface AlertContextProps {
    showAlert: (message: string, type: AlertColor, timeout?: number) => void;
}

const AlertContext = createContext<AlertContextProps | undefined>(undefined)

export const useAlert = (): AlertContextProps => {
    const context = useContext(AlertContext)
    if (!context) {
        throw new Error('useAlert must be used within an AlertProvider')
    }
    return context
}

interface AlertProviderProps {
    children: ReactNode;
}

export const AlertProvider: React.FC<AlertProviderProps> = ({children}) => {
    const [alert, setAlert] = useState<{ message: string; type: AlertColor, timeout?: number } | null>(null)

    const showAlert = useCallback((message: string, type: AlertColor, timeout?: number) => {
        setAlert({message, type, timeout})
    }, [])

    const handleClose = () => {
        setAlert(null)
    }

    return (
        <AlertContext.Provider value={{showAlert}}>
            {children}
            {alert && (
                <Snackbar
                    key={alert.message}
                    open
                    autoHideDuration={alert.timeout || 6000}
                    onClose={handleClose}
                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                >
                    <Alert onClose={handleClose}
                           severity={alert.type}
                           sx={{width: '100%'}}>
                        {alert.message}
                    </Alert>
                </Snackbar>
            )}
        </AlertContext.Provider>
    )
}