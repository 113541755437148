import {FC, useEffect} from "react"
import {Outlet} from "react-router-dom"
import useAuth from "@hooks/useAuth"
import useRefreshToken from "@hooks/useRefreshToken"

const PersistLogin: FC = () => {
    const refresh = useRefreshToken()
    const {auth} = useAuth()

    useEffect(() => {
        const verifyRefreshToken = async () => {
            try {
                await refresh()
            } catch (err) {
                console.error(err)
            }
        }

        verifyRefreshToken().then()

        const interval = setInterval(() => {
            verifyRefreshToken().then()
        }, 60000)

        return () => clearInterval(interval)
    }, [auth, refresh])

    return <Outlet/>
}

export default PersistLogin