import {Route} from "react-router-dom"
import ProjectHomePage from "./pages/HomePage"
import ProjectFeaturePage from "./pages/FeaturePage"
import ProjectPageLayout from "./components/ProjectPageLayout"
import ProjectAsperaPage from "./pages/AsperaPage"
import ProjectSlackPage from "./pages/SlackPage"

const buildProjectRoutes = () => {
    return (
        <Route path="/project/:projectId" element={<ProjectPageLayout/>}>
            <Route path="" element={<ProjectHomePage/>}/>
            <Route path="features" element={<ProjectFeaturePage/>}/>
            <Route path="slack" element={<ProjectSlackPage/>}/>
            <Route path="aspera" element={<ProjectAsperaPage/>}/>
        </Route>
    )
}

export default buildProjectRoutes