import {FC, PropsWithChildren} from "react"
import {Stack} from "@mui/material"

type TProps = {
    direction?: "row" | "column"
} & PropsWithChildren

const AppPage: FC<TProps> = (props) => {
    const {children, direction} = props

    return (
        <Stack className={"AppPage"}
               direction={direction || "row"}
               paddingTop={1}
               paddingLeft={1}
               flex={1}
               gap={2}
        >
            {children}
        </Stack>
    )
}

export default AppPage