import {FC} from "react"
import AppSidebar from "@components/AppSidebar"
import {List} from "@mui/material"
import SidebarItem from "./components/ProjectSidebarItem"
import {useDataContext} from "../../context/DataProvider"

type TProps = {}

const ProjectSidebar: FC<TProps> = () => {
    const {project} = useDataContext()

    if (!project) return null

    const thisUrl = "/project/" + project.id

    return (
        <AppSidebar title={project.name}>
            <List sx={{width: "230px", marginTop: "10px"}}>
                <SidebarItem to={thisUrl} primary="Home"/>
                <SidebarItem to={thisUrl + "/features"} primary="Features"/>
                <SidebarItem to={thisUrl + "/slack"} primary="Slack"/>
                <SidebarItem to={thisUrl + "/aspera"} primary="Aspera"/>
            </List>
        </AppSidebar>
    )
}

export default ProjectSidebar