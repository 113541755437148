import {AuthState} from "@type/auth"
import {SessionUser} from "@type/user"
import {clearSessionId, getSessionAuth, setSessionAuth} from "@utils/session"
import {createContext, FC, ReactElement, useCallback, useState} from "react"

type TAuthContext = {
    auth: AuthState | null
    user: SessionUser | null
    setAuth: (auth: AuthState | null) => void
    setUser: (user: SessionUser | null) => void
    clearAuth: () => void
}

const AuthContext = createContext<TAuthContext>({
    auth: null,
    user: null,
    setAuth: () => {
    },
    setUser: () => {
    },
    clearAuth: () => {
    },
})

type TPropsAuthProvider = {
    children: ReactElement
}

export const AuthProvider: FC<TPropsAuthProvider> = (props) => {
    const [user, setUser] = useState<SessionUser | null>(null)
    const [authData, setAuthData] = useState<AuthState | null>(getSessionAuth())

    const setAuth = useCallback((auth: AuthState | null) => {
        setAuthData(auth)
        setSessionAuth(auth)
    }, [setAuthData])

    const clearAuth = useCallback(() => {
        clearSessionId()
        setAuth(null)
        setUser(null)
    }, [setAuth, setUser])

    return (
        <AuthContext.Provider value={{auth: authData, user, setAuth, setUser, clearAuth}}>
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthContext