import {FC} from "react"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import {useLocation} from "react-router"
import useNavigateWithParams from "@hooks/useNavigateWithParams"

interface SidebarItemProps {
    to: string
    primary: string
}

const SidebarItem: FC<SidebarItemProps> = ({to, primary}) => {
    const navigateWithParams = useNavigateWithParams()
    const location = useLocation()
    const isActive = (path: string) => location.pathname === path

    return (
        <ListItemButton
            selected={isActive(to)}
            onClick={() => navigateWithParams(to)}
        >
            <ListItemText primary={primary}/>
        </ListItemButton>
    )
}

export default SidebarItem