import React, {FC, useState} from "react"
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography} from "@mui/material"
import {AsperaApi, AsperaShare} from "@api/aspera"
import {useAlert} from "@context/AlertContext"
import {AsperaNodeDropdown} from "@components/aspera/AsperaNodeDropdown"

type Project = {
    id: string
    name: string
}

type TProps = {
    project: Project
    open: boolean
    onClose: () => void
    onCreate: (share: AsperaShare) => void
}

const toKebabCase = (str: string) => {
    return str
        .toLowerCase()
        .replace(/[\s_]+/g, '-')
}

const AsperaShareCreateDialog: FC<TProps> = (props) => {
    const {project, open, onClose, onCreate} = props

    const [nodeId, setNodeId] = useState<number | null>(null)
    const [suffix, setSuffix] = useState<string>("")
    const {showAlert} = useAlert()

    const shareName = suffix === ""
        ? `${project.name}`.toLowerCase()
        : `${project.name}__${toKebabCase(suffix)}`.toLowerCase()

    const handleCreate = async () => {
        if (!nodeId) {
            showAlert("Please select a node", "error")
            return
        }
        try {
            const newShare = await AsperaApi.createShare(
                project.id,
                nodeId,
                shareName.trim(),
                `/${shareName}`,
                true,
            )
            onCreate(newShare)
            onClose()
        } catch (error) {
            showAlert(`${error}`, "error")
        }
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Create New Share</DialogTitle>
            <DialogContent>
                <Stack gap={2}>
                    <AsperaNodeDropdown value={nodeId ? [nodeId] : []}
                                        onValueChange={
                                            (nodeIds) => setNodeId(nodeIds[0] || null)
                                        }
                                        multiSelectEnabled={false}/>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Share Suffix"
                        type="text"
                        fullWidth
                        value={suffix}
                        onChange={(e) => setSuffix(e.target.value)}
                    />
                    <Typography variant="body1"
                                fontSize={18}
                                color={"text.primary"}>
                        {shareName}
                    </Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleCreate} color="primary">
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AsperaShareCreateDialog