// noinspection JSUnusedGlobalSymbols

export enum Role {
    Admin = "admin",
    Scheduler = "scheduler",
    Executive = "executive",
    Supervisor = "supervisor",
    Producer = "producer",
    Lead = "lead",
    Dmg = "dmg",
    Default = "default",
}

export const RoleArray = [
    Role.Admin,
    Role.Scheduler,
    Role.Executive,
    Role.Supervisor,
    Role.Producer,
    Role.Lead,
    Role.Dmg,
    Role.Default,
]

type PageRolesType = {
    Files: Role[];
    ProjectLeads: Role[];
    Projects: Role[];
    RenderFarm: Role[];
    SpeedTest: Role[];
    Workstations: Role[];
};

export const PageRoles: PageRolesType = {
    Files: RoleArray.filter(role => role !== Role.Scheduler),
    ProjectLeads: [Role.Admin, Role.Executive, Role.Producer],
    Projects: RoleArray,
    RenderFarm: RoleArray.filter(role => role !== Role.Scheduler),
    SpeedTest: [Role.Admin, Role.Scheduler],
    Workstations: [Role.Admin, Role.Scheduler],
}
