import {Role} from "@utils/roles"
import {useBaseFeature} from "@hooks/useBaseFeature"

export enum Feature {
    MANAGE = "manage",
}

const FEATURES = {
    [Feature.MANAGE]: new Set([
        Role.Admin,
        Role.Executive,
        Role.Producer,
        Role.Supervisor,
        Role.Dmg,
    ]),
}

export const useFeature = () => {
    return useBaseFeature(FEATURES)
}