import {useCallback} from "react"
import useAuth from "./useAuth"

const useLogout = (): (() => Promise<void>) => {
    const {clearAuth} = useAuth()
    return useCallback(async () => {
        clearAuth()
    }, [clearAuth])
}

export default useLogout