import {Route} from "react-router-dom"
import {UserDetailPage} from "./pages/UserDetailPage"
import {SpeedTestPage} from "./pages/SpeedTestPage"

export const buildSpeedTestRoutes = () => {
    return (
        <>
            <Route path="/speedtest" element={<SpeedTestPage/>}/>
            <Route path="/speedtest/:userId" element={<UserDetailPage/>}/>
        </>
    )
}
