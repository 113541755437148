import React, {FC} from "react"
import {Autocomplete, Box, CircularProgress, Stack, TextField, Typography} from "@mui/material"
import {useAsperaShareData} from "@components/aspera/AsperaShareDropdown/hooks/useAsperaShareData"
import {AsperaShare} from "@api/aspera"

type TProps = {
    value: number[]
    onValueChange: (shareIds: number[]) => void
    multiSelectEnabled: boolean
} & {
    size?: "small" | "medium" | undefined,
    sx?: any,
}

export const AsperaShareDropdown: FC<TProps> = (props) => {
    const {value, onValueChange, multiSelectEnabled, ...other} = props
    const {loading, shares} = useAsperaShareData()

    const handleChange = (_: any, newValue: AsperaShare | AsperaShare[] | null) => {
        if (!newValue) {
            onValueChange([])
        } else if (!Array.isArray(newValue)) {
            onValueChange([newValue.id])
        } else {
            const shareIds = (newValue as AsperaShare[]).map(share => share.id)
            onValueChange(shareIds)
        }
    }

    if (loading) return <CircularProgress/>

    return (
        <Box sx={{width: 300}}>
            <Autocomplete
                {...other}
                options={shares}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                    <li {...props} style={{display: 'flex', alignItems: 'center'}}>
                        <Stack direction={"column"}>
                            <Typography>{option.name}</Typography>
                            <Typography variant={"body2"} color={"grey"}>{option.name}</Typography>
                        </Stack>
                    </li>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={multiSelectEnabled ? "Select Shares" : "Select Share"}
                        variant="outlined"
                        fullWidth
                    />
                )}
                onChange={handleChange}
                multiple={multiSelectEnabled}
                value={multiSelectEnabled ?
                    shares.filter(share => value.includes(share.id)) :
                    shares.find(share => value.includes(share.id)) || null}
            />
        </Box>
    )
}