import {runMethod} from "@api/base"

export type AuthUser = {
    id: number
    name: string
    email: string
    is_active: boolean
    expires: string
    last_test_timestamp?: number
}

const expireUser = async (uid: number): Promise<void> => {
    await runMethod({
        method: "GET",
        route: `/api/v1/speedtest/user/${uid}/expire`,
    })
}

const renewUser = async (uid: number): Promise<void> => {
    await runMethod({
        method: "GET",
        route: `/api/v1/speedtest/user/${uid}/renew`,
    })
}

const createUser = async (name: string, email: string): Promise<AuthUser> => {
    const response = await runMethod<{ data: AuthUser }>({
        method: "POST",
        route: "/api/v1/speedtest/user",
        body: {name, email},
    })
    return response.data
}

// const deleteUser = async (uid: number): Promise<void> => {
//     await runMethod({
//         method: "DELETE",
//         route: `/api/v1/speedtest/user/${uid}`,
//     })
// }

const sendEmail = async (email: string, body: string, site: string): Promise<void> => {
    await runMethod({
        method: "POST",
        route: "/api/v1/speedtest/sendmail",
        body: {email, body, site},
    })
}

export class SpeedTestApi {
    static expireUser = expireUser
    static renewUser = renewUser
    static createUser = createUser
    // static deleteUser = deleteUser
    static sendEmail = sendEmail
}