import React, {FC} from "react"
import {Autocomplete, Avatar, Box, CircularProgress, TextField} from "@mui/material"
import {usePersonData} from "@components/person/Dropdown/hooks/usePersonData"
import {Person} from "@components/person/Dropdown/query/person.query"

type TProps = {
    value: string[]
    onValueChange: (userIds: string[]) => void
    multiSelectEnabled: boolean
} & {
    size?: "small" | "medium" | undefined,
    sx?: any,
}

const PersonDropdown: FC<TProps> = (props) => {
    const {value, onValueChange, multiSelectEnabled, ...other} = props
    const {data, loading, error} = usePersonData()

    const handleChange = (_: any, newValue: Person | Person[] | null) => {
        if (!newValue) {
            onValueChange([])
        } else if (!Array.isArray(newValue)) {
            onValueChange([newValue.id])
        } else {
            const userIds = (newValue as Person[]).map(user => user.id)
            onValueChange(userIds)
        }
    }

    if (loading) return <CircularProgress/>
    if (error) return <div>Error loading users</div>

    return (
        <Box sx={{width: 300}}>
            <Autocomplete
                {...other}
                options={data?.users?.results || []}
                getOptionLabel={(option) => option.display_name}
                renderOption={(props, option) => (
                    <li {...props} style={{display: 'flex', alignItems: 'center'}}>
                        <Avatar
                            alt={option.id}
                            src={option.avatar_sm}
                            sx={{width: 24, height: 24, marginRight: 1}}
                        />
                        {option.display_name}
                    </li>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={multiSelectEnabled ? "Select Users" : "Select User"}
                        variant="outlined"
                        fullWidth
                    />
                )}
                onChange={handleChange}
                multiple={multiSelectEnabled}
                value={multiSelectEnabled ?
                    data?.users?.results.filter(user => value.includes(user.id)) :
                    data?.users?.results.find(user => value.includes(user.id)) || null}
            />
        </Box>
    )
}

export default PersonDropdown