import React, {FC} from "react"
import {Autocomplete, Box, CircularProgress, Stack, TextField, Typography} from "@mui/material"
import {useAsperaUserData} from "@components/aspera/AsperaUserDropdown/hooks/useAsperaUserData"
import {AsperaUser} from "@api/aspera"

type TProps = {
    value: number[]
    onValueChange: (userIds: number[]) => void
    multiSelectEnabled: boolean
} & {
    size?: "small" | "medium" | undefined,
    sx?: any,
}

export const AsperaUserDropdown: FC<TProps> = (props) => {
    const {value, onValueChange, multiSelectEnabled, ...other} = props
    const {loading, users} = useAsperaUserData()

    const handleChange = (_: any, newValue: AsperaUser | AsperaUser[] | null) => {
        if (!newValue) {
            onValueChange([])
        } else if (!Array.isArray(newValue)) {
            onValueChange([newValue.user_id])
        } else {
            const userIds = (newValue as AsperaUser[]).map(user => user.user_id)
            onValueChange(userIds)
        }
    }

    if (loading) return <CircularProgress/>

    return (
        <Box sx={{width: 300}}>
            <Autocomplete
                {...other}
                options={users}
                getOptionLabel={(option) => (
                    `${option.first_name} ${option.last_name} (${option.email})`
                )}
                renderOption={(props, option) => (
                    <li {...props} style={{display: 'flex', alignItems: 'center'}}>
                        <Stack direction={"column"}>
                            <Typography>{`${option.first_name} ${option.last_name}`}</Typography>
                            <Typography variant={"body2"} color={"grey"}>({option.email})</Typography>
                        </Stack>
                    </li>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={multiSelectEnabled ? "Select Users" : "Select User"}
                        variant="outlined"
                        fullWidth
                    />
                )}
                onChange={handleChange}
                multiple={multiSelectEnabled}
                value={multiSelectEnabled ?
                    users.filter(user => value.includes(user.user_id)) :
                    users.find(user => value.includes(user.user_id)) || null}
            />
        </Box>
    )
}