import {useAlert} from "@context/AlertContext"
import {useEffect, useState} from "react"
import {AsperaApi, AsperaShare} from "@api/aspera"

export const useAsperaShareData = () => {
    const [loading, setLoading] = useState<boolean>(true)
    const [shares, setShares] = useState<AsperaShare[]>([])
    const {showAlert} = useAlert()

    useEffect(() => {
        setLoading(true)
        AsperaApi.listShares()
            .then((res) => {
                setShares(res.sort((a, b) => a.name.localeCompare(b.name)))
            })
            .catch((error) => showAlert(error.message, "error"))
            .finally(() => setLoading(false))
    }, [showAlert])

    return {loading, shares}
}