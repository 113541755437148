import React, {useEffect, useState} from "react"
import {
    CircularProgress,
    Fab,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material"

import AddIcon from "@mui/icons-material/Add"
import {AuthUser} from "@api/speedtest"
import {MinimalAuthUser, useSpeedTestUsers} from "../hooks/useSpeedTestUsers"
import {AuthUserLine} from "../components/AuthUserLine"
import {useNavigate} from "react-router"
import AppPage from "@components/AppPage"
import PageContent from "@components/PageContent"
import NewUserDialog from "../components/NewUserDialog"

export const SpeedTestPage = () => {
    const [authUsers, setAuthUsers] = useState<MinimalAuthUser[]>([])
    const [isNewDialogOpen, setIsNewDialogOpen] = useState(false)
    const [filter, setFilter] = useState("")
    const {result: resUsers, refetch: refetchUsers, loading} = useSpeedTestUsers()
    const navigate = useNavigate()

    useEffect(() => {
        setAuthUsers(resUsers || [])
    }, [resUsers])

    const showDetail = (id: number) => {
        navigate(`/speedtest/${id}`)
    }

    const handleUserCreated = (user: AuthUser) => {
        refetchUsers().then()
        showDetail(user.id)
    }

    if (loading && !authUsers.length) {
        return <AppPage><CircularProgress/></AppPage>
    }

    // Apply the filter to authUsers
    const filteredUsers = authUsers.filter(user =>
        user.name.toLowerCase().includes(filter.toLowerCase()),
    )

    return (
        <AppPage>
            <PageContent>
                <Grid container={true}
                      flexDirection={"column"}
                      flexGrow={1}
                      flexWrap={"nowrap"}>
                    <NewUserDialog
                        open={isNewDialogOpen}
                        onClose={() => setIsNewDialogOpen(false)}
                        onUserCreated={handleUserCreated}
                    />

                    <TableContainer sx={{minHeight: "12vh"}}>
                        {/* Search bar and add button */}
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <TextField
                                            label="Search by name"
                                            type={"search"}
                                            variant={"outlined"}
                                            onChange={(event) => setFilter(event.target.value)}
                                            fullWidth={true}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <Fab
                                            size="small"
                                            color="primary"
                                            aria-label="add"
                                            onClick={() => setIsNewDialogOpen(!isNewDialogOpen)}
                                        >
                                            <AddIcon/>
                                        </Fab>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                    </TableContainer>

                    {/* User table */}
                    <TableContainer>
                        <Table stickyHeader>
                            {/* Table headers */}
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Expires</TableCell>
                                    <TableCell>Last Test</TableCell>
                                    <TableCell align="center">Toggle</TableCell>
                                </TableRow>
                            </TableHead>

                            {/* Table body */}
                            <TableBody>
                                {filteredUsers.map((user) => (
                                    <AuthUserLine
                                        key={user.id}
                                        user={user}
                                        showDetail={() => showDetail(user.id)}
                                        onChange={() => refetchUsers().then()}
                                    />
                                ))}
                            </TableBody>

                            {/* Table footer */}
                            <TableFooter>
                                <TableRow>
                                    <TableCell colSpan={5}></TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Grid>
            </PageContent>
        </AppPage>
    )
}
