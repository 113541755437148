import React, {FC, useState} from "react"
import {useAlert} from "@context/AlertContext"
import ConfirmationDialog from "@components/dialogs/ConfirmationDialog"
import {IconButton, Stack, Typography} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import {Feature, useFeatureSlackPage} from "../../../hooks/useFeatureSlackPage"
import {SlackApi} from "@api/slack"

type TMemberProps = {
    channelId: string
    email: string
    onChange: () => void
}

export const MemberRow: FC<TMemberProps> = (props) => {
    const {channelId, email, onChange} = props

    const {showAlert} = useAlert()
    const [removeUser, setRemoveUser] = useState<string | null>(null)
    const {ftCheck} = useFeatureSlackPage()

    const allowManageMembers = ftCheck(Feature.MANAGE_MEMBERS)

    const handleRemoveUser = () => {
        setRemoveUser(email)
    }

    const removeUserConfirmationDialog = (
        !!removeUser
            ? (() => (
                <ConfirmationDialog
                    title={"Remove User"}
                    message={`Are you sure you want to remove ${email} from the channel?`}
                    open={!!removeUser}
                    onCancel={() => setRemoveUser(null)}
                    onAccept={() => {
                        SlackApi.removeUserFromChannel(channelId, email)
                            .then(() => onChange())
                            .catch((err) => showAlert(`Failed to remove user - ${err.message}`, "error"))
                            .finally(() => setRemoveUser(null))
                    }}
                />
            ))()
            : null
    )

    return (
        <>
            {removeUserConfirmationDialog}
            <Stack gap={1}
                   direction={"row"}
                   alignItems={"center"}>
                {
                    allowManageMembers
                        ? (
                            <IconButton size="small" onClick={() => handleRemoveUser()}>
                                <CloseIcon color={"error"} fontSize="small"/>
                            </IconButton>
                        )
                        : null
                }
                <Typography width={"150px"}>{email}</Typography>
            </Stack>
        </>
    )
}

