import {useCallback} from "react"
import {isExpiringSoon} from "@utils/session"
import useAuth from "./useAuth"
import {OAuthApi} from "@api/oauth"

const useRefreshToken = (): (() => Promise<void>) => {
    const {auth, setAuth} = useAuth()

    return useCallback(async () => {
        if (auth && isExpiringSoon()) {
            OAuthApi.refreshLogin(auth.accessToken)
                .then((newToken: string | null) => {
                    setAuth(newToken ? {accessToken: newToken} : null)
                })
                .catch((error) => {
                    console.error(`Failed to refresh token - ${error}`)
                })
        }
    }, [auth, setAuth])
}

export default useRefreshToken