import React, {FC} from "react"
import {Autocomplete, Box, CircularProgress, Stack, TextField, Typography} from "@mui/material"
import {useAsperaNodeData} from "@components/aspera/AsperaNodeDropdown/hooks/useAsperaNodeData"
import {AsperaNode} from "@api/aspera"

type TProps = {
    value: number[]
    onValueChange: (nodeIds: number[]) => void
    multiSelectEnabled: boolean
} & {
    size?: "small" | "medium" | undefined,
    sx?: any,
}

export const AsperaNodeDropdown: FC<TProps> = (props) => {
    const {value, onValueChange, multiSelectEnabled, ...other} = props
    const {loading, nodes} = useAsperaNodeData()

    const handleChange = (_: any, newValue: AsperaNode | AsperaNode[] | null) => {
        if (!newValue) {
            onValueChange([])
        } else if (!Array.isArray(newValue)) {
            onValueChange([newValue.id])
        } else {
            const nodeIds = (newValue as AsperaNode[]).map(node => node.id)
            onValueChange(nodeIds)
        }
    }

    if (loading) return <CircularProgress/>

    return (
        <Box sx={{width: 300}}>
            <Autocomplete
                {...other}
                options={nodes}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                    <li {...props} style={{display: 'flex', alignItems: 'center'}}>
                        <Stack direction={"column"}>
                            <Typography>{option.name}</Typography>
                            <Typography variant={"body2"} color={"grey"}>{option.name}</Typography>
                        </Stack>
                    </li>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={multiSelectEnabled ? "Select Nodes" : "Select Node"}
                        variant="outlined"
                        fullWidth
                    />
                )}
                onChange={handleChange}
                multiple={multiSelectEnabled}
                value={multiSelectEnabled ?
                    nodes.filter(node => value.includes(node.id)) :
                    nodes.find(node => value.includes(node.id)) || null}
            />
        </Box>
    )
}