import {FC, PropsWithChildren} from "react"
import {Box, Stack, Typography} from "@mui/material"
import {CollapsibleSidebar} from "@components/CollapsibleSidebar"

type TProps = {
    title: string
    width?: number
} & PropsWithChildren

const AppSidebar: FC<TProps> = (props) => {
    const {children, title} = props

    return (
        <CollapsibleSidebar
            height={'calc(100vh - 64px)'}
            width={props.width}
        >
            <Box sx={{flexShrink: 0}}>
                <Typography
                    variant={"h5"}
                    paddingBottom={2}
                >
                    {title}
                </Typography>
                <Stack paddingLeft={1}>
                    {children}
                </Stack>
            </Box>
        </CollapsibleSidebar>
    )
}

export default AppSidebar