import React, {useCallback, useState} from "react"
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material"

type PromptDialogProps = {
    title: string
    message: string
    label?: string
    onAccept: (input: string) => void
    onCancel?: () => void
    isOpen: boolean
    handleClose: () => void
    pattern?: RegExp
}

export const PromptDialog: React.FC<PromptDialogProps> = (props) => {
    const {title, label, message, onAccept, onCancel, isOpen, handleClose, pattern} = props

    const [input, setInput] = useState<string>("")
    const [error, setError] = useState<boolean>(false)

    const handleAccept = useCallback(() => {
        if (input === "" || (pattern && !pattern.test(input))) {
            setError(true)
            return
        }
        onAccept(input)
        handleClose()
    }, [input, onAccept, handleClose, pattern])

    const handleCancel = useCallback(() => {
        if (onCancel) {
            onCancel()
        }
        handleClose()
    }, [onCancel, handleClose])

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value
        setInput(newValue)
        if (newValue === "" || (pattern && !pattern.test(newValue))) {
            setError(true)
        } else {
            setError(false)
        }
    }, [pattern])

    return (
        <Dialog open={isOpen} onClose={handleCancel}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {message}
                <TextField
                    autoFocus
                    margin="dense"
                    label={label || "User Input"}
                    fullWidth
                    value={input}
                    onChange={handleChange}
                    error={error}
                    helperText={error ? "Invalid input" : ""}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={handleAccept} color="primary">
                    Accept
                </Button>
            </DialogActions>
        </Dialog>
    )
}