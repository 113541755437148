import {StrictMode} from "react"
import ReactDOM from "react-dom/client"
import {BrowserRouter, Route, Routes} from "react-router-dom"
import App from "./App"
import {AuthProvider} from "@context/AuthProvider"
import reportWebVitals from "./reportWebVitals"
import {AlertProvider} from "@context/AlertContext"

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <StrictMode>
        <BrowserRouter>
            <AuthProvider>
                <AlertProvider>
                    <Routes>
                        <Route path="/*" element={<App/>}/>
                    </Routes>
                </AlertProvider>
            </AuthProvider>
        </BrowserRouter>
    </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
