import React, {createContext, FC, PropsWithChildren, useContext} from "react"

type TDataContext = {}

export const SlackContext = createContext<TDataContext>({})

type TPropsDataProvider = {} & PropsWithChildren

export const SlackDataProvider: FC<TPropsDataProvider> = (props) => {
    const {children} = props

    return (
        <SlackContext.Provider value={{}}>
            {children}
        </SlackContext.Provider>
    )
}

export const useSlackContext = () => {
    return useContext(SlackContext)
}
