import {Role} from "@utils/roles"
import {useBaseFeature} from "@hooks/useBaseFeature"

export enum Feature {
    IMPERSONATE = "impersonate",
}

const FEATURES = {
    [Feature.IMPERSONATE]: new Set([Role.Admin]),
}

export const useFeatureApp = () => {
    return useBaseFeature(FEATURES)
}