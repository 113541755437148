import {FC} from "react"
import {Stack, Typography} from "@mui/material"
import {useDataContext} from "../../context/DataProvider"

const ProjectHomePageComponent: FC = () => {
    return null
}

type TProps = {}

const ProjectHomePage: FC<TProps> = () => {
    const {project} = useDataContext()

    if (!project) return null

    return (
        <Stack width={"100%"} spacing={2}>
            <Typography variant="h4">{project.title}</Typography>
            <ProjectHomePageComponent/>
        </Stack>
    )
}

export default ProjectHomePage